<div [formGroup]="parentForm">
  <table class="w-100 mb-3" *ngIf="routes.controls.length > 0">
    <tr class="font-small">
      <td width="20%">
        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.ac">AC</span>&nbsp;
        <span class="required-asterisk" *ngIf="checkRequiredInput(routes.at(0).get('airlineId'))">*</span>
      </td>
      <td width="20%">
        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.flight">Flight</span>&nbsp;
        <span class="required-asterisk" *ngIf="checkRequiredInput(routes.at(0).get('flight'))">*</span>
      </td>
      <td width="20%">
        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.route">Date</span>&nbsp;
        <span class="required-asterisk" *ngIf="checkRequiredInput(routes.at(0).get('dt'))">*</span>
      </td>
      <td width="20%">
        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.from">From</span>&nbsp;
        <span class="required-asterisk" *ngIf="checkRequiredInput(routes.at(0).get('departureId'))">*</span>
      </td>
      <td width="20%">
        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.to">To</span>&nbsp;
        <span class="required-asterisk" *ngIf="checkRequiredInput(routes.at(0).get('arrivalId'))">*</span>
      </td>
      <td width="35px" *ngIf="!disabled"></td>
      <td width="35px" *ngIf="!disabled"></td>
    </tr>
    <ng-container [formArrayName]="arrayName">
      <ng-container *ngFor="let route of routes.controls; let i=index">
        <tr [formGroupName]="i">
          <td>
            <div *ngIf="!disabled">
              <ng-select class="w-100 custom-sm p-0 select-long" id="airlineId_{{i}}"
                [items]="referenceAirline.data" bindLabel="iata"
                bindValue="id" appendTo="body" [selectOnTab]="true" [virtualScroll]="true"
                [loading]="referenceAirline.loading" (scroll)="selectFuc.onScroll($event, referenceAirline, 'airlines')"
                (scrollToEnd)="selectFuc.onScrollToEnd(referenceAirline, 'airlines')"
                [clearable]="false"
                [searchFn]="selectFuc.customSearchAirport" (change)="setAttribute(route, 'airline', $event.iata);"
                formControlName="airlineId">
                <ng-template ng-option-tmp let-item="item">
                  <div class="font-small">
                    <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="disabled">      
              <input formControlName="airline" class="form-control custom-sm" type="text" readonly>
            </div>
          </td>
          <td>
            <input type="text" class="form-control custom-sm" formControlName="flight" [readonly]="disabled" appUpperCase />
          </td>
          <td>
            <input type="date" class="form-control custom-sm"
              (change)="parseDateReactiveForm(routes.at(i).get('dt'), $event.target.value)"
              [value]="routes.at(i).get('dt').value | date: 'yyyy-MM-dd'"
              [disabled]="disabled">
          </td>
          <td>
            <div *ngIf="!disabled">
              <ng-select class="w-100 custom-sm p-0 select-long" [items]="referenceAirport.data" bindLabel="iata"
                bindValue="id" appendTo="body" [selectOnTab]="true" [virtualScroll]="true"
                [loading]="referenceAirport.loading" (scroll)="selectFuc.onScroll($event, referenceAirport, 'airports')"
                (scrollToEnd)="selectFuc.onScrollToEnd(referenceAirport, 'airports')"
                [clearable]="false"
                [searchFn]="selectFuc.customSearchAirport" (change)="setAttribute(route, 'departure', $event.iata);"
                formControlName="departureId">
                <ng-template ng-option-tmp let-item="item">
                  <div class="font-small">
                    <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="disabled">      
              <input formControlName="departure" class="form-control custom-sm" type="text" readonly>
            </div>
          </td>
          <td>
            <div *ngIf="!disabled">
              <ng-select class="w-100 custom-sm p-0 select-long" [items]="referenceAirport.data" bindLabel="iata"
                bindValue="id" appendTo="body" [selectOnTab]="true" [virtualScroll]="true"
                [loading]="referenceAirport.loading" (scroll)="selectFuc.onScroll($event, referenceAirport, 'airports')"
                (scrollToEnd)="selectFuc.onScrollToEnd(referenceAirport, 'airports')"
                [clearable]="false"
                [searchFn]="selectFuc.customSearchAirport" (change)="setAttribute(route, 'arrival', $event.iata);"
                formControlName="arrivalId" [attr.disabled]="disabled ? 'disabled' : null">
                <ng-template ng-option-tmp let-item="item">
                  <div class="font-small">
                    <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="disabled">      
              <input formControlName="arrival" class="form-control custom-sm" type="text" readonly>
            </div>
          </td>
          <td *ngIf="!disabled">
            <button *ngIf="i === routes.length -1" type="button" class="btn btn-h-29 btn-ico" (click)="addFlight(routes);" (keypress.enter)="addFlight(routes);">
              <span class="material-icons text-green">add</span></button>
          </td>
          <td *ngIf="!disabled">
            <span class="material-icons text-red" (click)="removeFlight(routes, i);">close</span>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
  <div class="text-right mb-3" *ngIf="!disabled">
    <button class="btn btn-sm btn-blue" i18n="laf|Main - button@@laf.main.button.addFlight"
      (click)="addFlight(routes)">
      Add flight
    </button>
  </div>
</div>