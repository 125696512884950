<div class="modal-dialog modal-xl">
    <div class="modal-content bg-light-gray">
        <form [formGroup]="parentForm">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalContent">
                    <span i18n="laf|Content modal@@laf.modal.pilferage.header">Pilferage</span>
                </h5>
                <button type="button" class="close" [attr.data-dismiss]="parentForm.valid || pilferage.controls.length === 0 ? 'modal' : ''" aria-label="Close" (click)="closeForm()">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body" formArrayName="items">
                <ng-container *ngFor="let pilferageItem of pilferage.controls; let i=index">
                    <div class="row mb-2" [formGroupName]="i">
                        <div class="col pr-0">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                    <span class="input-group-text font-small py-0" i18n="laf|laf|Content modal@@laf.statement.pilferage.table.lost">Lost</span>
                                </div>
                                <input type="text" class="w-100 form-control custom-sm" formControlName="claim" />
                            </div>
                        </div>
                        <div class="col pr-0">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                    <span class="input-group-text font-small py-0" i18n="laf|laf|Content modal@@laf.statement.pilferage.table.description">Description</span>
                                </div>
                                <input type="text" class="w-100 form-control custom-sm" formControlName="note" />
                            </div>
                        </div>
                        <div class="col pr-0">
                            <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                    <span class="input-group-text font-small py-0" i18n="laf|laf|Content modal@@laf.statement.pilferage.table.value">
                    Value
                  </span>
                                </div>
                                <input type="number" class="w-100 form-control custom-sm" formControlName="declaredValue" />
                            </div>
                        </div>
                        <div class="col-auto">
                            <span class="material-icons text-red" (click)="removePilferage(i);">close</span>
                        </div>
                    </div>
                </ng-container>
                <div class="text-right">
                    <button class="btn btn-sm btn-blue" i18n="laf|Main - button@@laf.main.button.add" (click)="addPilferage()">
            Add
          </button>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-blue" [attr.data-dismiss]="parentForm.valid || pilferage.controls.length === 0 ? 'modal' : ''" i18n="laf|Content modal@@laf.modal.baggageContent.button.ok" (click)="closeForm()">
          OK
        </button>
            </div>
        </form>
    </div>
</div>