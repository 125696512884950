import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowModalMessageComponent } from './show-modal-message/show-modal-message.component';
import { UpperCaseDirective } from './directive/upper-case.directive';
import { AddressValidatorDirective } from './directive/address-validator.directive';
import { ModalMessageComponent } from './components/modal-message/modal-message.component';
import { PhoneMaskDirective } from './directive/phone-mask.directive';


@NgModule({
  declarations: [
    ShowModalMessageComponent,
    UpperCaseDirective,
    AddressValidatorDirective,
    ModalMessageComponent,
    PhoneMaskDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ShowModalMessageComponent,
    UpperCaseDirective,
    AddressValidatorDirective,
    ModalMessageComponent,
    PhoneMaskDirective,
  ]
})
export class SharedModule { }
