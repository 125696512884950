import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalSettings, TypeEnterprise, ModuleName } from '@settings/global-settings';
import { Referance, Statement, StatementData, Baggage } from '../types/statements';
import { Subject, timer } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { LostFoundModuleRestApiService } from '../services/lost-found-rest-api.service';
import { ADMIN_DATA, LOCAL_REFERENCES } from '../types/const';
import { parseDate } from '@shared/functions/dateFunctions';
import { GlobalI18n } from '@settings/global-i18n';
import { ReferencesService } from '../services/references.service';
import { Router } from '@angular/router';
import { References, REFERENCES_LIST } from '../types/types';
import { BaggageTableComponent } from './baggage-table/baggage-table.component';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormsService } from '../services/forms.service';
import { ViewParamsService } from '../services/view-lostfound.service';

@Component({
  selector: 'desktop',
  templateUrl: './desktop-baggage.component.html',
  styleUrls: ['./desktop-baggage.component.less']
})
export class DesktopBaggageComponent implements OnInit {
  @ViewChild('closeButton') closeButton: any;
  @ViewChild('newClaimAirport') newClaimAirport: NgSelectComponent;
  @ViewChild(ModalMessageComponent) modalError: ModalMessageComponent;

  @ViewChild(BaggageTableComponent) baggageTableComponent: BaggageTableComponent;

  ADMIN_DATA = ADMIN_DATA;
  LOCAL_REFERENCES = LOCAL_REFERENCES;
  parseDate = parseDate;

  typeEnterprise = TypeEnterprise;

  classes = {
    baggage: Baggage,
  };

  numberOfItemsFromEndBeforeFetchingMore = 10;

  error = {
    errorMessage: '',
    errorType: '',
  };

  references: References = new References();

  selectLoadAnimation = {
    notifications: false,
    worldTracer: false,
    baggage_statuses_animation: false,
    baggage_brands: false
  };

  buffer = {
    airlines: [],
    airports: [],
  };

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    airlines: 50,
    airports: 50,
  };

  baggageInternalForCategory: Array<Referance> = [];
  // Текущая выбранная категория для содержимого багажа
  baggageInternalCategoryId: number = null;
  // Текущая выбранный элемент в категории для содержимого багажа
  baggageInternalId: number = null;
  // Текущее описание элемента в категории для содержимого багажа
  baggageInternalDescription: string = null;

  marks: Array<string> = [];

  statements: Array<Statement>;
  statement: StatementData = new StatementData();
  statementForm: UntypedFormGroup;

  autoSaveData = new Subject();

  userReferences = {
    airports: {loading: true, data: []},
    airlines: {loading: true, data: []},
  }

  loading = true;

  public addTagClaim: (name) => void;
  public addTagBaggageTag: (name) => void;

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
    public globalI18n: GlobalI18n,
    public referencesService: ReferencesService,
    public viewParams: ViewParamsService,
    private router: Router,
    private formsService: FormsService,
    private fb: UntypedFormBuilder
  ) {
    globalSettings.loadDefaultConfig();
    this.addTagClaim = (name) => this.addTagFilterFromArray(name, 'name');
    this.addTagBaggageTag = (name) => this.addTagFilterFromArray(name, 'tag');
    this.statementForm = this.fb.group({});
  }

  ngOnInit(): void {
    const referencesList = [
      REFERENCES_LIST.airlines,
      REFERENCES_LIST.airports,
      REFERENCES_LIST.baggage_types,
      REFERENCES_LIST.baggage_colors,
      REFERENCES_LIST.baggage_materials,
      REFERENCES_LIST.baggage_elements,
      REFERENCES_LIST.baggage_statuses,
    ];
    // 1 Неприбытие, 5 Невостребованный, 7 Засланный, 8 Задержанный, 3 Поврежденный, 2 Недостача
    const statementTypesOrder = [1, 5, 7, 8, 3, 2];
    this.formsService.createStatementForm(this.statement).then(data => {
      this.statementForm = data;
    });
    Promise.all([
      // загружаем справочники
      this.referencesService.loadReferences(referencesList).then(data => {
        this.references = data;
      }),
      // загружаем типы заявлений
      this.referencesService.loadStatementTypes(this.viewParams.filterParams, statementTypesOrder, this.globalSettings.language).then(data => {
        this.references.statement_types = data;
      }),
      this.referencesService.loadMasterData().then(data => {
        if (data.storages && data.storages.length > 0) {
          this.ADMIN_DATA.storages = data.storages;
        }
      }),
      this.referencesService.getUserAirlines().then((data: Array<Referance>) => {
        this.userReferences.airlines.data = data.sort((a, b) => a.iata > b.iata ? 1 : -1);
        this.userReferences.airlines.loading = false;
      }),
      this.referencesService.getUserAirports().then((data: Array<Referance>) => {
        this.userReferences.airports.data = data.sort((a, b) => a.iata > b.iata ? 1 : -1);
        this.userReferences.airports.loading = false;
      }),
      this.loadMarks(),
    ]).then(() => {
      this.refreshStatementIndicators(this.viewParams.showedStatement);
      this.loading = false;
    });

    // debounceTime() - время в миллисекундах перед сохранением данных, если в течении
    // этого времени приходят изменения то будет сохранено последнее состояние
    // distinctUntilChanged() - сравнение полученого объекта на изменения, если
    // данные прежние, сохранение не проихсодит
    // this.autoSaveData
    //   .pipe(
    //     debounceTime(350),
    //     distinctUntilChanged(),
    //     switchMap(async () => {
    //       await this.saveStatement();
    //     })
    //   ).subscribe(result => {
    //     // console.log("AutoSave result:", result);
    //   }, error => {
    //     // console.log("AutoSave error");
    //   });
  }

  getUserAirports() {
    this.restApi.getUserAirports().subscribe(data => {
      console.log('getUserAirports', data);
    });
  }

  getUserAirlines() {
    this.restApi.getUserAirlines().subscribe(data => {
      console.log('getUserAirlines', data);
    });
  }

  async refreshStatementIndicators(filterParams = null) {
    const xRequestId = this.globalSettings.randomUuid;
    let indicators;
    await this.restApi.getIndicators(xRequestId, filterParams, filterParams?.times).then(data => {
      indicators = data;
    });
    this.references.statement_types.data.forEach(statement => {
      statement.count = indicators.find(indicator => indicator.statementType === statement.id)?.count;
    });
  }

  get baggage_statuses() {
    for (const item of this.references.statement_types.data) {
      if (item.id === this.statement.typeId) {
        return item.baggage_statuses;
      }
    }
    return [];
  }

  get statementFormBaggages() {
    return this.statementForm?.get('baggage') as UntypedFormArray || new UntypedFormArray([]);
  }

  createStatement() {
    this.statement = new StatementData();
    // Для аэропорта автоматом заполняется поле Аэропорт в заявлении,
    // для авиакомпании поле Авиакомпания в заявлении
    if (this.globalSettings.typeEnterprise === this.typeEnterprise.Airport) {
      if (this.globalSettings.defaultAirportId) {
        this.statement.airportId = this.globalSettings.defaultAirportId;
      }
    } else {
      if (this.globalSettings.defaultAirlineId) {
        this.statement.airlineId = this.globalSettings.defaultAirlineId;
      }
    }
    this.formsService.createStatementForm(this.statement).then(data => {
      this.statementForm = data;
    });
    timer(700).subscribe(() => {
      this.newClaimAirport.focus();
    });
  }

  validationStatement() {
    const errorTag = [];
    const errorStatus = [];
    this.statement.baggage.forEach(baggage => {
      if (baggage.tag) {
        const testTag = /^([A-Za-z]{1})([A-Za-z]{1})|^([A-Za-z0-9]{1})([A-Za-z]{1})|^([A-Za-z]{1})([A-Za-z0-9]{1})/i.test(baggage.tag);
        if (!testTag) {
          errorTag.push(baggage.tag);
        }
      }

      // TODO
      // Проверка признака ухода заявления в Архив, данный признак так же
      // является требованием для проставления Кода розыска по заявлению
      if (this.getById(this.baggage_statuses, baggage.statusId)?.last && !baggage.irregularityCode1Id) {
        errorStatus.push(baggage.tag);
      }
    });

    if (errorTag.length > 0) {
      const errorMessage = this.globalI18n.getMessage(ModuleName.LostFound, 'baggageTagError') + ': ' + errorTag.join(', ');
      const errorType = 'error';
      this.modalError.showErrorMess(errorMessage, errorType);
      return false;
    }

    if (errorStatus.length > 0) {
      const errorMessage = this.globalI18n.getMessage(ModuleName.LostFound, 'baggageStatusCodeError') + ': ' + errorStatus.join(', ');
      const errorType = 'error';
      this.modalError.showErrorMess(errorMessage, errorType);
      return false;
    }

    return true;
  }

  appendingLoader = false;
  async addStatement() {
    this.statementForm.markAllAsTouched();
    this.statementForm.updateValueAndValidity();
    if (this.statementForm.valid) {
      this.appendingLoader = true;
      const xRequestId = this.globalSettings.randomUuid;
      this.statement = new StatementData(this.statementForm.value);
      const data = await this.restApi.addStatement(this.statement, xRequestId);
      // Получение данных из заголовков ответа
      const location = data.headers.get('Location');
      this.statement.id = location.replace('/statements/', '');
      this.closeButton.nativeElement.click();
      this.appendingLoader = false;
      this.router.navigate(['/lostfound/', this.statement.id]);
      return;
    }
  }

  async loadMarks() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getMarks(xRequestId);
    this.marks = data;
  }

  // async loadAdminData() {
  //   const xRequestId = this.globalSettings.randomUuid;
  //   for (const key in this.ADMIN_DATA) {
  //     if (Object.prototype.hasOwnProperty.call(this.ADMIN_DATA, key)) {
  //       this.selectLoadAnimation[key] = true;
  //       await this.restApi.getAdminData(key, xRequestId).then(data => {
  //         if (data && data.length > 0) {
  //           this.ADMIN_DATA[key] = data;
  //         }
  //         // }, err => {
  //         //   this.displayError(err);
  //       });
  //       this.selectLoadAnimation[key] = false;
  //     }
  //   }
  // }

  onScrollToEndNgSelect(name) {
    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].data.slice(len, this.bufferSize[name] + len);
    this.references[name].loading = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.references[name].loading = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param term Строка для поиска введеня пользователем
   * @param item Элемент для поиска
   */
  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
      (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
      (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 5 ?
        (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
        :
        (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }

  // Обработка значения, введенного в поле фильтра
  addTagFilterFromArray(names, key) {
    this.viewParams.addTagFilterFromArray(names, key);
  }

  clearFilterParametr(field: string, event) {
    event.stopPropagation();
    this.viewParams.clearFilterParametr(field);
    this.baggageTableComponent.loadStatements();
  }

  quickSearchCheck() {
    return this.viewParams.quickSearchCheck();
  }

  getById(array: Array<any>, id: any) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

  filterApp() {
    this.viewParams.filterApp();
    this.baggageTableComponent.loadStatements();
  }

  filterSwitch() {
    this.viewParams.filterSwitch();
    this.baggageTableComponent.loadStatements();
  }

  clearFilter() {
    this.viewParams.clearFilter();
  }

  filterArchive() {
    this.viewParams.filterArchive();
    this.baggageTableComponent.loadStatements();
  }

  toggleItem(array, item) {
    setTimeout(() => {
      if (array.includes(item)) {
        array.splice(array.indexOf(item), 1);
      } else {
        array.push(item);
      }
      this.baggageTableComponent.loadStatements();
    }, 250);
  }

  changeMark(mark?: string) {
    this.viewParams.changeMark(mark);
    this.baggageTableComponent.loadStatements();
  }

  addItem(array, type) {
    if (array !== null) {
      const item = new type();
      array.push(item);
    }
  }

  addBaggageToStatement() {
    this.statementFormBaggages.push(this.formsService.createBaggageToStatementForm());
  }

  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message === 'object') {
        if (this.globalSettings.language === 'en') {
          this.error.errorMessage = err.message[0];
        } else {
          this.error.errorMessage = err.message[1];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

}
