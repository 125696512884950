import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { parseDateReactiveForm } from '@shared/functions/dateFunctions';
import { NgSelectFunctions } from '@shared/functions/ngSelectFunctions';
import { FormsService } from 'src/app/lost-found-module/services/forms.service';

@Component({
  selector: 'app-routes-table',
  templateUrl: './routes-table.component.html',
  styleUrls: ['./routes-table.component.less']
})
export class RoutesTableComponent implements OnInit {
  @Input() routes: UntypedFormArray;
  @Input() parentForm: UntypedFormGroup;
  @Input() referenceAirport; // : {data: [], loading: boolean};
  @Input() referenceAirline; // : {data: [], loading: boolean};
  @Input() arrayName: string;
  @Input() disabled?: boolean;

  selectFuc = new NgSelectFunctions();
  parseDateReactiveForm = parseDateReactiveForm;

  constructor(
    private formsService: FormsService
  ) { }

  ngOnInit(): void {
  }

  parseDateInStatementForm(index: number, date?: string): void {
    const value = this.routes.at(index);
    if (value) {
      if (date) {
        value.patchValue({ dt: new Date(date).toISOString() });
      } else {
        value.patchValue({ dt: null });
      }
    }
  }

  setAttribute(array: UntypedFormGroup, item: string, val: string) {
    array.get(item).patchValue(val);
  }

  removeFlight(routes: UntypedFormArray, index: number) {
    routes.removeAt(index);
  }

  addFlight(routes: UntypedFormArray) {
    const i = this.routes.controls.length - 1;
    const route = this.formsService.createFlightToStatementForm();
    if (this.routes.controls.length > 0) {
      const arrivalId = this.routes.at(i).get('arrivalId').value;
      const arrival = this.routes.at(i).get('arrival').value;
      route.patchValue({ departureId: arrivalId, departure: arrival });
    }
    routes.push(route);
    // TODO: не ставится курсор почему-то в поле, хотя находит его нормально
    setTimeout(() => {
      const element = document.getElementById('airlineId_' + (i + 1));
      console.log(element);
      document.getElementById('airlineId_' + (i + 1)).focus();
    }, 0);
  }

  checkRequiredInput(input) {
    return input.hasValidator(Validators.required);
  }

}
