import { DAMAGES } from '../types/const';
import { LANGUAGES } from '../../shared/const';

export class Statement {
  constructor(obj?: Statement, private language?: string) {
    obj && Object.assign(this, obj);
    this.dtInsert = new Date(this.dtInsert).getFullYear() > 2000 ? this.dtInsert : null;
  }
  agent: string;
  airport: string;
  statementId: string;
  baggageId: string;
  mark: string;
  arrival: {
    flight: string;
    dt: Date;
  };
  statementName: string;
  dtInsert: Date | null | any;
  passenger: string;
  passengerStatus: string;
  baggage: {
    reason: string;
    tag: string;
    status: string;
    statusId: number;
    wt: string;
  };
  baggageType: {
    code: string;
    name: string;
  };
  color: {
    code: string;
    name: string;
  };
  material: {
    code: string;
    name: string;
  };
  externalElements: Array<number>;
  indication: boolean;

  get baggageReasonStr() {
    if (this.language && this.baggage.reason && this.baggage.reason.length > 0) {
      if (this.language === LANGUAGES.RU && this.baggage.reason.length > 1) {
        return this.baggage.reason[1];
      } else if (this.language === LANGUAGES.EN) {
        return this.baggage.reason[0];
      }
    }
    return '';
  }

  get baggageStatusStr() {
    if (this.language && this.baggage.status && this.baggage.status.length > 0) {
      if (this.language === LANGUAGES.RU && this.baggage.status.length > 1) {
        return this.baggage.status[1];
      } else if (this.language === LANGUAGES.EN) {
        return this.baggage.status[0];
      }
    }
    return '';
  }
}

export class StatementData {
  static readonly BAG_CHARACTERISTIC_X_ELEMENT = 7;
  constructor(obj?: StatementData) {
    if (obj) {
      Object.assign(this, obj);
      const dueDate = this.passenger.additionalInfo.temporary.dueDate;
      this.passenger.additionalInfo.temporary.dueDate = new Date(dueDate).getFullYear() > 2000 ? dueDate : null;
      if (!this.passenger.additionalInfo.passengers) {
        this.passenger.additionalInfo.passengers = [];
        this.passenger.additionalInfo.passengers.push(new Passenger());
        this.passenger.additionalInfo.passengers.push(new Passenger());
      }
      this.baggage = [];
      obj.baggage.forEach(el => {
        const baggage = new Baggage(el);
        if (!baggage.characteristics.externalElements || baggage.characteristics.externalElements.length < 2) {
          baggage.characteristics.externalElements.push(StatementData.BAG_CHARACTERISTIC_X_ELEMENT);
          baggage.characteristics.externalElements.push(StatementData.BAG_CHARACTERISTIC_X_ELEMENT);
        }
        this.baggage.push(baggage);
      });
    } else {
      this.id = null;
      this.airportId = null;
      this.airlineId = null;
      this.baggageId = '';
      this.baggage = [new Baggage()];
      this.mark = '';
      this.passenger = {
        passenger: new Passenger(),
        amount: null,
        pnr: '',
        ticket: '',
        phone1: '',
        phone2: '',
        email: '',
        address: '',
        notification: ['Email', 'SMS'],
        baggage: {
          amount: null,
          weight: null,
        },
        additionalInfo: {
          class: '',
          language: '',
          amount: null,
          gender: '',
          overknightKit: {
            male: null,
            female: null,
          },
          ffp: '',
          status: '',
          temporary: {
            address: '',
            phone: '',
            dueDate: null,
          },
          passengers: [new Passenger(), new Passenger()]
        }
      };
      this.route = {
        full: {
          flights: []
        }
      };
      this.messages = {
        incoming: '',
        outgoing: '',
      };
    }
  }

  id: string | null;
  baggageId: string;
  airportId: number | null;
  airport: string;
  airlineId: number | null;
  airline: string;
  archived: boolean;
  name: string;
  typeId: number;
  type: string;
  mobile: boolean;
  mark: string;
  dtInsert: Date;
  route: {
    full: {
      flights: Array<Flight>;
    }
  };
  passenger: {
    passenger: Passenger;
    amount: number | null;
    pnr: string;
    ticket: string;
    phone1: string;
    phone2: string;
    email: string;
    address: string;
    notification: Array<string>;
    baggage: {
      amount: number | null;
      weight: number | null;
    };
    additionalInfo: {
      class: string;
      language: string;
      amount: number | null;
      gender: string;
      overknightKit: {
        male: number | null;
        female: number | null;
      };
      ffp: string;
      status: string;
      temporary: {
        address: string;
        phone: string;
        dueDate: Date | null | any;
      };
      passengers: Array<Passenger>;
    }
  };
  baggage: Array<Baggage>;
  messages: {
    incoming: string;
    outgoing: string;
  };
  username: string;
  commentary: string;

  get passengerRoute() {
    let route = '';
    if (this.route.full.flights && this.route.full.flights.length) {
      route += this.route.full.flights[0].departure;
      this.route.full.flights.forEach(el => {
        route += '/' + el.arrival;
      })
    }
    return route;
  }

  get passengerFullName() {
    let fullName = '';
    fullName = this.passenger.passenger.surname;
    if (this.passenger.passenger.name) {
      fullName += '/' + this.passenger.passenger.name;
    }

    if (this.passenger.passenger.middleName) {
      fullName += ' ' + this.passenger.passenger.middleName;
    }
    return fullName;
  }

  get passengerAllSurname() {
    let result = this.passenger.passenger.surname;
    if (this.passenger.additionalInfo.passengers.length > 0) {
      this.passenger.additionalInfo.passengers.forEach(item => {
        result += item.surname !== '' ? '\n' + item.surname : '';
      });
    }
    return result;
  }

  get flights() {
    let returnValue = '';
    let dateOld = '';
    this.route.full.flights.forEach(item => {
      const date = new Date(item.dt);
      const dateReturn = date.getDate() + date.toLocaleDateString("en-US", {month: "short"});
      if (dateOld === '') {
        dateOld = dateReturn;
      }
      returnValue += (dateOld !== dateReturn ? ('/' + dateOld.toUpperCase()) : '') + '/' + item.airline + item.flight;
      dateOld = dateReturn;
    });
    
    return returnValue.slice(1) + '/' + dateOld.toUpperCase();
  }
}

export class Passenger {
  constructor() {
    this.surname = '';
    this.name = '';
    this.middleName = '';
    this.title = '';
  }

  surname: string;
  name: string;
  middleName: string;
  title: string;

  get fullName(): string {
    return this.surname + ' / ' + this.name + ' / ' + this.middleName;
  }
}

export class Baggage {
  constructor(obj?) {
    if (obj) {
      obj && Object.assign(this, obj);
      this.storage.dtStart = new Date(this.storage.dtStart).getFullYear() > 2000 ? this.storage.dtStart : null;
      this.storage.dtFinish = new Date(this.storage.dtFinish).getFullYear() > 2000 ? this.storage.dtFinish : null;
      this.delivery.dt = new Date(this.delivery.dt).getFullYear() > 2000 ? this.delivery.dt : null;
      this.delivery.actualDt = new Date(this.delivery.actualDt).getFullYear() > 2000 ? this.delivery.actualDt : null;
      // this.damage.damages = [];
      // obj.damage.damages.forEach(item => {
      //   const damage = new Damage(item);
      //   this.damage.damages.push(damage);
      // })
      if (this.damage.damages && this.damage.damages.length > 0) {
        if (this.damage.damages[0]) {
          this.damage.damages[0] = new Damage(this.damage.damages[0]);
        } else {
          this.damage.damages[0] = new Damage();
        }
        if (this.damage && this.damage.damages[1]) {
          this.damage.damages[1] = new Damage(this.damage.damages[1]);
        } else {
          this.damage.damages[1] = new Damage();
        }
      } else {
        this.damage.damages = [new Damage(), new Damage()];
      }
      if (!this.forwarding.arrival.flights) {
        this.forwarding.arrival.flights = [];
      }
      if (!this.forwarding.departure.flights) {
        this.forwarding.departure.flights = [];
      }
      if (!this.characteristics.internalElements) {
        this.characteristics.internalElements = [];
      }
      if (this.characteristics.colorId === 0) {
        this.characteristics.colorId = null;
      }
      if (this.characteristics.materialId === 0) {
        this.characteristics.materialId = null;
      }
      if (this.characteristics.typeId === 0) {
        this.characteristics.typeId = null;
      }
      if (this.characteristics.brand === '') {
        this.characteristics.brand = null;
      }
      if (this.statusId === 0) {
        this.statusId = null;
      }
      if (this.airportId === 0) {
        this.airportId = null;
      }
      if (this.irregularityCode1Id === 0) {
        this.irregularityCode1Id = null;
      }
    } else {
      this.id = null;
      this.noTag = false;
      this.tag = '';
      this.weight = {
        expected: null,
        actual: null,
      };
      this.characteristics = {
        categoryId: null,
        typeId: null,
        materialId: null,
        colorId: null,
        externalElements: [7, 7], // По умолчанию (X, X - No external elements)
        lockCode: '',
        description: '',
        brand: '',
        internalElements: [],
      };
      this.route = [];
      this.routeExtendsFlight = true;
      this.forwarding = {
        arrival: {
          tag: '',
          flights: [],
        },
        departure: {
          tag: '',
          flights: [],
        }
      };
      this.storage = {
        id: null,
        name: '',
        dtStart: null,
        dtFinish: null,
        note: '',
        opened: false,
      };
      this.delivery = {
        enabled: true,
        dt: null,
        actualDt: null,
        address: '',
        note: '',
        courier: '',
      };
      this.worldTracer = {
        typeId: null,
        type: '',
        statement: '',
        statusId: null,
        status: '',
      };
      this.damage = {
        declaredValue: null,
        note: '',
        damages: [new Damage(), new Damage()]
      };
      this.pilferage = {
        items: [],
      };
      this.bagData = [];
      this.photo = new Array<Media>(6);
      this.attachment = new Array<Media>(2);
      this.possibleTransfer = {
        airports: []
      }
    }
  }
  id: string | null;
  noTag: boolean;
  tag: string;
  bagData: Array<BagData>;
  weight: {
    expected: number | null;
    actual: number | null;
  };
  airportId: number;
  irregularityCode1Id: number;
  irregularityCode2Id: number;
  irregularityInfo: string;
  statusId: number;
  characteristics: {
    categoryId: number | null;
    typeId: number | null;
    materialId: number | null;
    colorId: number | null;
    externalElements: Array<number>;
    lockCode: string;
    description: string;
    brand: string;
    internalElements: Array<InternalElement>;
  };
  worldTracer: {
    typeId: number | null;
    type: string;
    statement: string;
    statusId: number | null;
    status: string;
  };
  forwarding: {
    arrival: {
      tag: string;
      flights: Array<Flight>;
    };
    departure: {
      tag: string;
      flights: Array<Flight>;
    }
  };
  delivery: {
    enabled: boolean;
    dt: Date | null | any;
    actualDt: Date | null | any;
    address: string;
    note: string;
    courier: string;
  };
  storage: {
    id: number | null;
    name: string;
    dtStart: Date | null | string | any;
    dtFinish: Date | null | string | any;
    note: string;
    opened: boolean;
  };
  photo: Array<Media>;
  attachment: Array<Media>;
  media: Array<Media>;
  passengerTag: {
    destinationId: number;
    destination: string;
    passenger: string;
    phone: string;
    email: string;
    address: string;
  };
  route: Array<Flight>;
  routeExtendsFlight: boolean;
  damage: {
    declaredValue: number | null;
    note: string;
    damages: Array<Damage>;
  };
  pilferage: {
    items: Array<PilferageItem>;
  };
  additionalInfo: {
    excessBaggage: string,
    limitationOfLiability: string,
    insurance: true,
    customsInfo: string,
  };
  dtClosed: Date;
  possibleTransfer: {
    airports: Array<number>;
  }

  get damageLength() {
    const damages = this.damage.damages;
    if (damages && damages[0].codeString && damages[1].codeString) {
      return 2;
    } else if (damages && damages[0].codeString) {
      return 1;
    } else if (damages && damages[1].codeString) {
      return 1;
    } else {
      return 0;
    }
  }

  get fullRoute() {
    let route = '';
    let last = '';

    if (this.route && this.route.length) {
      route += this.route[0].departure;
      this.route.forEach(el => {
        route += '/' + el.arrival;
      })
    }
    return route;
  }

  get fullFlight() {
    const route = [];
    if (this.route && this.route.length) {
      this.route.forEach(item => {
        if (item.flight !== '' && !route.includes(item.flight)) {
          route.push(item.flight);
        }
      });
    }
    return route.join('/');
  }

  get name(){
    return this.id || '';
  }
}

export class InternalElement {
  categoryId: number;
  categoryName: Array<string>;
  internals: Array<InternalElementInternal>;
}

export class InternalElementInternal {
  id: number;
  name: Array<string>;
  description: string;
}

export class PilferageItem {
    claim: string;
    note: string;
    declaredValue: number;
}

export class Damage {
  constructor(obj?: Damage) {
    this.side1 = obj?.side1 || false;
    this.side2 = obj?.side2 || false;
    this.end1 = obj?.end1 || false;
    this.end2 = obj?.end2 || false;
    this.top = obj?.top || false;
    this.bottom = obj?.bottom || false;
    this.levelId = obj?.levelId || null;
    this.level = obj?.level || '';
    this.description = obj?.description || '';
    this.claim = obj?.claim || null;
    this.typeId = obj?.typeId || null;
    this.type = obj?.type || '';
    switch (obj?.side) {
      case DAMAGES.SIDE1:
        this.side1 = true;
        break;
      case DAMAGES.SIDE2:
        this.side2 = true;
        break;
      case DAMAGES.END1:
        this.end1 = true;
        break;
      case DAMAGES.END2:
        this.end2 = true;
        break;
      case DAMAGES.TOP:
        this.top = true;
        break;
      case DAMAGES.BOTTOM:
        this.bottom = true;
        break;
      default:
        break;
    }
  }

  side1: boolean;
  side2: boolean;
  end1: boolean;
  end2: boolean;
  top: boolean;
  bottom: boolean;
  levelId: number | null;
  level: string;
  description: string;
  claim: number | null;
  typeId: number | null;
  type: string;

  get side(): string {
    if (this.side1) {
      return DAMAGES.SIDE1;
    } else if (this.side2) {
      return DAMAGES.SIDE2;
    } else if (this.end1) {
      return DAMAGES.END1;
    } else if (this.end2) {
      return DAMAGES.END2;
    } else if (this.top) {
      return DAMAGES.TOP;
    } else if (this.bottom) {
      return DAMAGES.BOTTOM;
    } else {
      return null;
    }
  }

  set side(value) {
    this.side1 = false;
    this.side2 = false;
    this.end1 = false;
    this.end2 = false;
    this.top = false;
    this.bottom = false;
    switch (value) {
      case DAMAGES.SIDE1:
        this.side1 = true;
        break;
      case DAMAGES.SIDE2:
        this.side2 = true;
        break;
      case DAMAGES.END1:
        this.end1 = true;
        break;
      case DAMAGES.END2:
        this.end2 = true;
        break;
      case DAMAGES.TOP:
        this.top = true;
        break;
      case DAMAGES.BOTTOM:
        this.bottom = true;
        break;
      default:
        break;
    }
  }

  get codeString(): string {
    if (this.side || this.type || this.level) {
      return (this.side.replace(' ', '') + this.type + this.level).toUpperCase();
    } else {
      return '';
    }
  }

}

export class Flight {
  constructor() {
    this.airlineId = null;
    this.airline = '';
    this.flight = '';
    this.dt = null;
    this.departureId = null;
    this.departure = '';
    this.arrivalId = null;
    this.arrival = '';
  }
  airlineId: number;
  airline: string;
  flight: string;
  dt: Date | null;
  departureId: number;
  departure: string;
  arrivalId: number;
  arrival: string;
}

class Media {
  id: string;
  note: string;
}

class Route {
  constructor() {
    this.arrival = {
      airlineId: null,
      airline: '',
      flight: '',
      dt: null,
      departure: '',
      arrival: '',
      ticket: '',
      pnr: '',
    };
    this.full = {
      routeId: [],
      route: [],
      flights: [],
    };
  }
  arrival: {
    airlineId: number | null;
    airline: string;
    flight: string;
    dt: Date | null;
    departure: string;
    arrival: string;
    ticket: string;
    pnr: string;
  };
  full: {
    routeId: Array<number>;
    route: Array<string>;
    flights: Array<Flight>;
  };
}

export class CommercialAct {
  constructor(baggageId?) {
    this.baggageId = baggageId || null;
    this.comission = [];
    this.dtInsert = null;
    this.passenger = {
      passenger: '',
      phone1: '',
      phone2: '',
      passport: '',
      dateOfBirth: null,
    };
    this.route = new Route();
  }
  id: number;
  baggageId: number;
  name: string;
  dtInsert: Date | null;
  airportId: number;
  airlineId: number;
  reasonId: number;
  passenger: {
    passenger: string;
    phone1: string;
    phone2: string;
    passport: string;
    dateOfBirth: Date | null;
  };
  route: Route;
  comission: Array<Comission>;
  baggage: {
    tag: string;
    declaredValue: number;
    receipt: string;
    defectFromBaggageStatement: string;
    infoFromTag: {
      pieces: number;
      weight: number;
      departure: number;
      arrival: number;
    },
    pieces: number;
    weight: number;
    isPacked: boolean;
    isOpened: boolean;
    defect: string;
    additionalInfo: string;
    note: string;
    seal: {
      enabled: boolean;
      name: string;
    }
  };
}

export class Comission {
  position: string;
  fio: string;
}

export class Referance {
  constructor(public lang?: string) {}
  id?: number;
  name?: Array<string>;
  iata?: string;
  icao: string;
  code?: string;

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return '';
    }
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return '';
    }
  }

  get namesString(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1] + ',' + this.name[0];
    } if ((this.name) && (this.name.length === 1)) {
      return this.name[0];
    }
    else {
      return '';
    }
  }

  get nameLocalString(): string {
    if (this.name && this.name.length === 2 && this.lang === LANGUAGES.RU) {
      return this.name[1];
    } else if (this.name && this.name.length >= 1 && this.lang === LANGUAGES.EN) {
      return this.name[0];
    } else {
      return '';
    }
  }
}

export class StatementTypes extends Referance {
  constructor(lang?) {
    super(lang);
  }

  baggage_statuses?: Array<Referance>;
  count?: number;
}

export class BagData {
  statement: {
    id: string,
    name: string,
    station: {
      origin: string,
      airline: string,
      handler: string,
    },
    statement: {
      name: string,
    },
    passenger: {
      name: string,
      pnr: string
    },
    baggage: {
      tag: string,
      weight: 0,
      type: string,
      material: string,
      color: string,
      elements: Array<string>,
    },
    flight: Array<Flight>;
    lastupdate: Date;
  };
  baggage: {
    id: string,
  };
  score: number;
  canSelect: boolean;
  selected: boolean;
}
