<div class="bg-light-gray border-bottom py-1 pr-3 font-small">
  <div class="d-flex justify-content-end">
    <div class="d-flex flex-nowrap mr-2 align-items-center per-page">
      <div class="text-nowrap line-height-1" i18n="laf|Baggage@@laf.baggage.table.perPage">Per page</div>
      <select id="perPage" class="form-control form-control-sm ml-2 per-page" name="perPage"
        [ngModel]="viewParams.pagination.perPage" (change)="changePerPage(+$event.target.value)">
        <option *ngFor="let count of viewParams.pagination.countsPerPage" [value]="count">
          {{count}}
        </option>
      </select>
    </div>
    <pagination-controls (pageChange)="loadStatements($event)" maxSize="7" class="d-flex" directionLinks="true"
      autoHide="true" previousLabel="" nextLabel=""
      i18n-previousLabel="laf|Baggage@@laf.main.table.pagination.previousLabel"
      i18n-nextLabel="laf|Baggage@@laf.main.table.pagination.nextLabel">
    </pagination-controls>
  </div>
</div>
<div class="statements-table" [ngClass]="{'bottom-panel-active': statement.id}">
  <div class="loading" *ngIf="loading">
    <div class="cssload-spin-box"></div>
  </div>
  <table class="table-striped table-border w-100 table-sticky-header font-small">
    <thead class="bg-gray text-center">
      <tr>
        <th width="35px"></th>
        <th width="35px"></th>
        <!-- <th i18n="laf|Baggage@@laf.baggage.table.column.airport" *ngIf="userRole.includes('airline')">Airport</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.airline" *ngIf="userRole.includes('handler')">AC</th> -->
        <th i18n="laf|Baggage@@laf.baggage.table.column.airport">Airport</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.airline">AC</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.flight">Flight</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.route">Route</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.namePassenger">Name</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.baggageTag">Baggage tag</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.baggageCode">Baggage code</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.claimType">Claim type</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.passengerStatus">Passenger status</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.claimNumber">Claim number</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.at">At</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.status">Status</th>
        <th i18n="laf|Baggage@@laf.baggage.table.column.agent">Agent</th>
      </tr>
    </thead>
    <tbody>
      <!-- (click)="loadStatement(statementItem.statementId, stat_i);"
            (dblclick)="openStatement()" -->
      <tr class="text-center" *ngFor="let statementItem of statements
                      | paginate: {
                        itemsPerPage: viewParams.pagination.perPage,
                        currentPage: viewParams.pagination.pageActive,
                        totalItems: viewParams.pagination.statementsCount
                      }; let stat_i = index" (click)="loadStatement(statementItem.statementId, stat_i);"
        (dblclick)="openStatement('/lostfound/'+statementItem.statementId)"
        [ngClass]="{'bg-gray-blue-dark text-white': stat_i === statementOfList }">
        <td><span class="indicator" *ngIf="statementItem.indication"></span></td>
        <td>
          <span *ngIf="statementItem.mark" [ngStyle]="{'color': statementItem.mark }" class="material-icons">flag</span>
        </td>
        <td>{{statementItem.airport}}</td>
        <td>{{statementItem.airline}}</td>
        <td>
          <span class="text-break" [ngClass]="{'text-white': stat_i === statementOfList }" [innerHTML]="extractFlight(statementItem.arrival.flight)"></span>
        </td>
        <td>
          <span class="text-break" [ngClass]="{'text-white': stat_i === statementOfList }" [innerHTML]="extractRoute(statementItem.arrival.flight)"></span>
        </td>
        <td>{{statementItem.passenger}}</td>
        <td>{{statementItem.baggage.tag}}</td>
        <td> {{ characteristicCodeString(statementItem) }}</td>
        <td>{{statementItem.baggageReasonStr}}</td>
        <td>{{statementItem.passengerStatus}}</td>
        <td>{{ characteristicCodeString(statementItem) !== 'XXXXXXX' ? statementItem.statementName : '' }}</td>
        <td class="text-uppercase">{{statementItem.dtInsert | date: 'ddMMM' : 'UTC+0'}}</td>
        <td>{{statementItem.baggageStatusStr}}</td>
        <td>{{statementItem.agent}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="bottom-panel bg-light-gray border-top px-3">
  <div class="row py-2" *ngIf="statement.id; else: statementNotSelected">
    <div class="col pr-0">
      <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
        <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.statementInfo.flight">Flight</span>
        <div class="btn-group">
          <button type="button" class="btn btn-xs btn-white mr-2" (click)="sendToArchive()" *ngIf="!statement.archived"
            ngbTooltip="Send to archive"
            i18n-ngbTooltip="laf|Baggage@@laf.baggage.statementInfo.statement.button.sendToArchive">
            <i class="material-icons">archive</i>
          </button>
          <button type="button" class="btn btn-xs btn-white mr-2" (click)="restoreFromArchive()"
            *ngIf="statement.archived" ngbTooltip="Restore from archive"
            i18n-ngbTooltip="laf|Baggage@@laf.baggage.statementInfo.statement.button.restoreFromArchive">
            <i class="material-icons">unarchive</i>
          </button>
          <div class="dropdown">
            <button class="btn btn-xs dropdown-toggle btn-white" type="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" ngbTooltip="Mark"
              i18n-ngbTooltip="laf|Baggage@@laf.baggage.statementInfo.statement.button.mark">
              <i class="material-icons" [ngStyle]="{'color': statement.mark }">assistant_photo</i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
              <button *ngFor="let mark of marks" class="dropdown-item" (click)="setMark(statement.id, mark)">
                <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                <span>{{mark}}</span>
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item font-small" (click)="unsetMark(statement.id)">
                <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.clear">Clear</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="w-100 info-table font-small">
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.flight">Flight</th>
          <td>
            {{ statement.flights }}
          </td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.codeshare">Codeshare</th>
          <td></td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.passengerRoute" class="text-nowrap">Passenger
            route</th>
          <td>{{ statement.passengerRoute }}</td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.baggageRoute" class="text-nowrap">Baggage route
          </th>
          <td>{{ getBaggageByTag(statements[statementOfList]?.baggage.tag)?.fullRoute}}</td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.baggageCountWeight" class="text-nowrap">Bags
            count / weight</th>
          <td>{{ statement.passenger.baggage.amount}}<span
              class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{statement.passenger.baggage.weight
            }}</td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.passengersCount" class="text-nowrap">Passengers
            count</th>
          <td>{{ statement.passenger.additionalInfo.amount}}</td>
        </tr>
      </table>
    </div>
    <div class="col-5 pr-0">
      <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
        <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.head">Passenger</span>
      </div>
      <table class="w-100 info-table font-small">
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.name">Name</th>
          <td>{{passengerFullName(statement.passenger.passenger)}}</td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.phoneEmail" class="text-nowrap">Phone / email
          </th>
          <td>{{ statement.passenger.phone1 }}<span class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{
            statement.passenger.email }}
          </td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.address">Address</th>
          <td>{{ statement.passenger.address }}</td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.temporaryAddress" class="text-nowrap">Temporary
            address</th>
          <td>{{ statement.passenger.additionalInfo.temporary.address }}</td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.pnr">PNR</th>
          <td>{{ statement.passenger.pnr }}</td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.passenger.genderLanguage" class="text-nowrap">Gender /
            language</th>
          <td>{{statement.passenger.additionalInfo.gender}}<span
              class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{statement.passenger.additionalInfo.language}}
          </td>
        </tr>
      </table>
    </div>
    <div class="col">
      <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
        <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.bottomPanel.baggage.head">Baggage</span>
      </div>
      <table class="w-100 info-table font-small">
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.baggage.countBrokenWeight">Count / broken weight</th>
          <td class="font-weoght-bold">
            1<span
              class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{getBaggageByTag(statements[statementOfList]?.baggage.tag)?.weight.expected
            - getBaggageByTag(statements[statementOfList]?.baggage.tag)?.weight.actual}}
          </td>
        </tr>
        <tr>
          <th i18n="laf|Baggage@@laf.baggage.bottomPanel.baggage.baggageTagStatus">Baggage tag / Status</th>
          <td height="60">{{statements[statementOfList]?.baggage.tag}}<span
              class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{ getById(baggage_statuses,
            statements[statementOfList]?.baggage.statusId)?.nameLocal }}</td>
        </tr>
      </table>
    </div>
  </div>
  <ng-template #statementNotSelected>
    <div class="d-flex align-items-center justify-content-center h-100 statement-not-selected">
      <span class="align-middle">
        <p class="h2 text-secondary" i18n="laf|Main - control panel@@laf.main.preview.statementNotSelected">
          Claim not selected
        </p>
      </span>
    </div>
  </ng-template>
</div>

<app-modal-message></app-modal-message>