export class Chat {
  constructor(obj?: Chat) {
    this.externalBaggageId = obj?.externalBaggageId || '';
    this.statementBaggageId = obj?.statementBaggageId || '';
    this.statementId = obj?.statementId || '';
    this.name = obj?.name || '';
  }
  externalBaggageId: string;
  statementBaggageId: string;
  statementId: string;
  name?: string;
}

export class ChatMessage {
  constructor(text?: string) {
    this.text = text || '';
    this.dt = new Date();
    this.direction = 1;
  }
  id: string;
  statementId: string;
  statementBaggageId: string;
  sender: string;
  senderType: string;
  dt: Date;
  text: string;
  direction: number;
}

export type ChatData = {
  statementBaggageId: string,
  statementId: string,
  statementName?: string,
  externalBaggageId: string,
  externalId?: string,
  externalName?: string,
};

