<div class="modal-dialog modal-dialog-centered modal-xxl">
  <div class="modal-content bg-light-gray">
    <form #filterForm="ngForm">
      <div class="modal-header">
        <h5 class="modal-title" i18n="laf|Statement tabs@@laf.modal.compare.bagsComparison">
          Bags comparison
        </h5>
        <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-3">
            <div class="card h-100">
              <div class="card-body" style="max-height: 600px; overflow-y: auto;">
                <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.modal.compare.bagsMatches">
                  Bags matches</p>
                <table class="w-100 font-small">
                  <thead>
                    <tr>
                      <th></th>
                      <th i18n="laf|Statement tabs@@laf.modal.compare.number">Number</th>
                      <th i18n="laf|Statement tabs@@laf.modal.compare.rating" class="text-center">Rating
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let baggageMatch of bagData; let bm_i = index;">
                      <td class="text-center">
                        <span *ngIf="!checkCompareItem(baggageMatch.baggage.id); else: remove"
                          class="material-icons text-dark-gray pt-1 pointer"
                          (click)="addToCompare(baggageMatch)">read_more</span>
                        <ng-template #remove>
                          <span class="material-icons text-dark-gray pt-1 pointer"
                            (click)="removeToCompare(baggageMatch.baggage.id)">close</span>
                        </ng-template>
                      </td>
                      <td>{{ baggageMatch.statement.name }}</td>
                      <td class="text-center">{{ baggageMatch.score?.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-9 pl-0">
            <div class="card h-100">
              <div class="card-body">
                <h5 class="font-weight-bold text-blue h5"
                  i18n="laf|Statement tabs@@laf.modal.compare.comparisonBagsCharacteristics">
                  Comparison of bags characteristics
                </h5>
                <div class="table-grid font-small">
                  <div class="div-striped">
                    <div></div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.selected">Selected</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.matchScore">Match score</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.dateCreation">Date of creation</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.airport">Airport</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.baggageTag">Baggage tag</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.fullNamePassenger">Full name of the passenger</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.bookingNumber">Booking number</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.flight">Flight</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.route">Route</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.baggageColor">Baggage color</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.baggageType">Baggage type</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.baggageMaterial">Baggage material</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.externalElements">External elements</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.brand">Brand</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.weightBaggage">Weight of baggage</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.content">Content</div>
                    <div i18n="laf|Statement tabs@@laf.modal.compare.description">Description</div>
                    <!-- <div i18n="laf|Statement tabs@@laf.modal.compare.photo">Photo</div> -->
                    <!-- *ngFor="let item of [].constructor(10); let i = index" -->
                  </div>
                  <div class="div-striped text-center border">
                    <!-- <div i18n="laf|Statement tabs@@laf.modal.compare.wantedBaggage" class="font-weight-bold py-1">
                      Wanted baggage
                    </div> -->
                    <div class="font-weight-bold py-1">
                      {{ getTypeName(statement.typeId) }}<br>{{ statement.name }}
                    </div>
                    <div></div>
                    <div></div>
                    <div>{{ statement.dtInsert | date: 'dd.MM.yyyy HH:mm' : 'UTC+0'}}</div>
                    <div>{{ getById(references.airports.data, statement.airportId)?.iata }}</div>
                    <div>{{ baggage.tag }}</div>
                    <div class="message-text-wrapping">{{ statement.passengerAllSurname }}</div>
                    <div>{{ statement.passenger.pnr }}</div>
                    <div>{{ getFlightsString(baggage.route) }}</div>
                    <div>{{ baggage.fullRoute }}</div>
                    <div>{{ getById(references.baggage_colors.data, baggage.characteristics.colorId)?.code }}</div>
                    <div>{{ getById(references.baggage_types.data, baggage.characteristics.typeId)?.code }}</div>
                    <div>{{ getById(references.baggage_materials.data, baggage.characteristics.materialId)?.code }}
                    </div>
                    <div>{{ getCodeExternalElements(baggage.characteristics.externalElements) }}</div>
                    <div>{{ baggage.characteristics.brand }}</div>
                    <div>{{ baggage.weight.expected }}</div>
                    <div>
                      <span class="material-icons pointer"
                        *ngIf="baggage.characteristics.internalElements && baggage.characteristics.internalElements.length > 0"
                        [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="click"
                        [autoClose]="false">done</span>
                      <ng-template #popTitle>
                        <span i18n="laf|Statement tabs@@laf.modal.compare.сontent">Content</span>
                      </ng-template>
                      <ng-template #popContent>
                        <div *ngFor="let element of getInternalElements(baggage.characteristics.internalElements)">
                          <div class="font-weight-bold text-gray-blue-dark">{{element.name}}</div>
                          <div *ngFor="let item of element.items" class="mb-2">
                            <div class="text-gray-blue-dark">{{item.name}}</div>
                            <div>{{item.description}}</div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <div>
                        <span class="material-icons pointer"
                              *ngIf="baggage.characteristics.description !== ''"
                              [ngbPopover]="descriptionContent"
                              [popoverTitle]="descriptionTitle"
                              triggers="click"
                              [autoClose]="false"
                              >done</span>
                        <ng-template #descriptionTitle>
                          <span i18n="laf|Statement tabs@@laf.modal.compare.description">Content</span>
                        </ng-template>
                        <ng-template #descriptionContent>
                          {{baggage.characteristics.description}}
                        </ng-template>
                      </div>
                      <!-- <div></div> -->
                  </div>
                  <div class="div-striped text-center" *ngFor="let baggageItem of baggageCompareArray">
                    <div>
                      <div class="d-flex align-items-center justify-content-center">
                        <span class="font-weight-bold">{{ baggageItem.statement.name }}</span>
                        <span class="material-icons text-dark-gray pointer"
                          (click)="removeToCompare(baggageItem.baggage.id)">close</span>
                      </div>
                      <button class="btn btn-sm btn-ico btn-light-gray" (click)="openChat(baggageItem)">
                        <span class="material-icons text-dark-gray">question_answer</span>
                        <span i18n="laf|Statement tabs@@laf.modal.compare.chat">Chat</span>
                      </button>
                    </div>
                    <div>
                      <input type="checkbox" *ngIf="!baggageItem.selected" [checked]="baggageItem.selected"
                        (click)="makeBaggageSelected(baggage.id, baggageItem)">
                      <input type="checkbox" *ngIf="baggageItem.selected" [checked]="baggageItem.selected"
                        (click)="makeBaggageUnselected(baggage.id, baggageItem)">
                    </div>
                    <div>{{ baggageItem.score?.toFixed(2) }}</div>
                    <div>{{ baggageItem.dtInsert | date: 'dd.MM.yyyy HH:mm' : 'UTC+0'}}</div>
                    <div></div>
                    <div [ngClass]="{'text-green font-weight-bold': baggage.tag === baggageItem.baggage.tag }">
                      {{ baggageItem.baggage.tag }}
                    </div>
                    <div>
                      <span
                        [ngClass]="{'text-green font-weight-bold': statement.passengerAllSurname.includes(baggageItem.passenger.name)}">
                        {{ baggageItem.passenger.name }}
                      </span>
                      <span *ngIf="baggageItem.passenger.additionalInfo.name1"
                        [ngClass]="{'text-green font-weight-bold': statement.passengerAllSurname.includes(baggageItem.passenger.additionalInfo.name1)}">
                        <br>{{ baggageItem.passenger.additionalInfo.name1 }}
                      </span>
                      <span *ngIf="baggageItem.passenger.additionalInfo.name2"
                        [ngClass]="{'text-green font-weight-bold': statement.passengerAllSurname.includes(baggageItem.passenger.additionalInfo.name2)}">
                        <br>{{ baggageItem.passenger.additionalInfo.name2 }}
                      </span>
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': statement.passenger.pnr === baggageItem.passenger.pnr }">
                      {{ baggageItem.passenger.pnr }}
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': getFlightsString(baggage.route) === getFlightsString(baggageItem.flights) }">
                      {{ getFlightsString(baggageItem.flights) }}
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': baggage.fullRoute === getRoutes(baggageItem.flights) }">
                      {{ getRoutes(baggageItem.flights) }}
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': getById(references.baggage_colors.data, baggage.characteristics.colorId)?.code === baggageItem.baggage.color }">
                      {{ baggageItem.baggage.color }}
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': getById(references.baggage_types.data, baggage.characteristics.typeId)?.code === baggageItem.baggage.type }">
                      {{ baggageItem.baggage.type }}
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': getById(references.baggage_materials.data, baggage.characteristics.materialId)?.code === baggageItem.baggage.material }">
                      {{ baggageItem.baggage.material }}
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': getCodeExternalElements(baggage.characteristics.externalElements) === getExternalElementsString(baggageItem.baggage.externals) }">
                      {{ getExternalElementsString(baggageItem.baggage.externals) }}
                    </div>
                    <!-- Brand -->
                    <div
                      [ngClass]="{'text-green font-weight-bold': baggage.characteristics.brand === baggageItem.baggage.brand }">
                      {{ baggageItem.baggage.brand }}
                    </div>
                    <div
                      [ngClass]="{'text-green font-weight-bold': baggage.weight.expected === baggageItem.baggage.weight }">
                      {{ baggageItem.baggage.weight }}
                    </div>
                    <div>
                      <!-- Content -->
                      <span class="material-icons pointer"
                        *ngIf="baggageItem.baggage.internals && baggageItem.baggage.internals.length > 0"
                        [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="click"
                        [autoClose]="false">done</span>
                      <ng-template #popTitle>
                        <span i18n="laf|Statement tabs@@laf.modal.compare.description">Content</span>
                      </ng-template>
                      <ng-template #popContent>
                        <div *ngFor="let element of baggageItem.baggage.internals">
                          <div class="font-weight-bold text-gray-blue-dark">{{element.category}}</div>
                          <div class="mb-2">
                            <div class="text-gray-blue-dark">{{element.elements[0]}}</div>
                            <div>{{element.note}}</div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <!-- Description -->
                    <div>
                        <span class="material-icons pointer"
                              *ngIf="baggageItem.baggage.description !== ''"
                              [ngbPopover]="descriptionContent"
                              [popoverTitle]="descriptionTitle"
                              triggers="click"
                              [autoClose]="false"
                              >done</span>
                        <ng-template #descriptionTitle>
                          <span i18n="laf|Statement tabs@@laf.modal.compare.description">Content</span>
                        </ng-template>
                        <ng-template #descriptionContent>
                          {{baggageItem.baggage.description}}
                        </ng-template>
                      </div>
                    <!-- Photo -->
                    <!-- <div>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal"
          i18n="laf|Baggage modal@@laf.modal.newStatement.button.close">
          Close
        </button>
      </div>
    </form>
  </div>
</div>