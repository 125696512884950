<form [formGroup]="statementForm" class="statement-form" novalidate>
    <div class="row border-bottom py-2 justify-content-between font-small mb-2">
        <div class="d-flex col align-items-center">
            <!-- (click)="loadWindow('statementsList');" -->
            <button type="button" class="btn btn-sm btn-ico btn-light-gray mr-2" [routerLink]="['/lostfound']"
                [queryParams]="{ table: 'baggage'}">
                <i class="material-icons mr-2">arrow_back_ios</i>
                <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.claims">Claims</span>
            </button>
            <div class="dropdown mr-2">
                <button type="button" class="btn btn-sm btn-light-gray dropdown-toggle" data-toggle="dropdown"
                    aria-expanded="false">
                    <i class="material-icons">print</i>
                    <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.actions">Actions</span>
                </button>
                <div class="dropdown-menu">
                    <h6 class="dropdown-header p-2"
                        i18n="laf|Main - control panel@@laf.main.controlPanel.dropdownHeader.print">
                        Print</h6>
                    <button type="button" *ngIf="statement.typeId != 2 && statement.typeId != 3; else damagePir"
                        class="btn btn-xs dropdown-item justify-content-start"
                        (click)="downloadDocument(statement.id, 'pir_pax')">
                        <!-- <i class="material-icons">receipt</i> -->
                        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.pirPassenger">PIR for
                            passenger</span>
                    </button>
                    <button type="button" class="btn btn-xs dropdown-item justify-content-start"
                        (click)="downloadDocument(statement.id, 'pir')">
                        <!-- <i class="material-icons">receipt</i> -->
                        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.pirIata">PIR IATA</span>
                    </button>
                    <ng-template #damagePir>
                        <button type="button" class="btn btn-xs dropdown-item justify-content-start"
                            (click)="downloadDocument(statement.id, 'dpr')">
                            <!-- <i class="material-icons">receipt</i> -->
                            <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.dpr">DPR</span>
                        </button>
                    </ng-template>
                    <button type="button" class="btn btn-xs dropdown-item justify-content-start"
                        (click)="downloadDocument(statement.id, 'commercial_act')">
                        <!-- <i class="material-icons">description</i> -->
                        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.commercialAct">Commercial
                            act</span>
                    </button>
                    <button type="button" class="btn btn-xs dropdown-item justify-content-start"
                        (click)="downloadDocument(statement.id, 'application')">
                        <!-- <i class="material-icons">find_in_page</i> -->
                        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.generateStatement">Wanted
                            claim</span>
                    </button>
                    <button type="button" class="btn btn-xs dropdown-item justify-content-start"
                        (click)="downloadDocument(statement.id, 'delivery_application')">
                        <!-- <i class="material-icons">local_shipping</i> -->
                        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.deliveryStatement">Delivery
                            claim</span>
                    </button>
                    <div class="dropdown-divider"></div>
                    <button type="button" class="btn btn-xs dropdown-item justify-content-start"
                        (click)="sendToArchive()" *ngIf="!statement.archived">
                        <!-- <i class="material-icons">archive</i> -->
                        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.sendToArchive">Send to
                            archive</span>
                    </button>
                    <button type="button" class="btn btn-xs dropdown-item justify-content-start"
                        (click)="restoreFromArchive()" *ngIf="statement.archived">
                        <!-- <i class="material-icons">unarchive</i> -->
                        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.restoreFromArchive">Restore
                            from
                            archive</span>
                    </button>
                    <a class="btn btn-xs dropdown-item justify-content-start" [routerLink]="['/reports']"
                        target="_blank" [queryParams]="{'id': statement.id, 'report_id': 30,  'reportGroup_id': 10}">
                        <!-- <i class="material-icons">history</i> -->
                        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.history">History</span>
                    </a>
                </div>
            </div>
            <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                    <span class="input-group-text font-small py-0">
                        <span i18n="laf|Statement tabs@@laf.main.controlPanel.reason">Reason</span>&nbsp;
                    </span>
                </div>
                <input type="text" class="form-control form-control-sm"
                    [value]="getById(references.statement_types.data, statementForm?.get('typeId')?.value)?.nameLocalString"
                    disabled>
            </div>
            <!-- <button type="button" class="btn btn-xs btn-light-gray mr-2" (click)="sendToArchive()"
        *ngIf="!statement.archived">
        <i class="material-icons">archive</i>
        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.sendToArchive">
          Send to archive
        </span>
      </button>
      <button type="button" class="btn btn-xs btn-light-gray mr-2" (click)="restoreFromArchive()"
        *ngIf="statement.archived">
        <i class="material-icons">unarchive</i>
        <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.restoreFromArchive">
          Restore from archive
        </span>
      </button>
      <a class="btn btn-sm btn-ico btn-light-gray mr-2" [routerLink]="['/reports']" target="_blank"
        [queryParams]="{'id': statement.id, 'report_id': 30,  'reportGroup_id': 10}">
        <i class="material-icons mr-2">history</i>
        <span i18n="laf|Main - control panel@@laf.main.controlPanel.button.history">History</span>
      </a> -->
        </div>
        <div class="col-auto d-flex align-items-center">
            <div class="input-group input-group-sm mr-2">
                <div class="input-group-prepend">
                    <span class="input-group-text font-small"
                        i18n="laf|Main - control panel@@laf.main.controlPanel.label.claimIdent">
                        Claim
                    </span>
                </div>
                <input type="text" class="form-control form-control-sm"
                    [value]="statementForm.valid ? statementForm?.get('name')?.value : ''" disabled>
            </div>
            <div class="input-group input-group-sm mr-2">
                <div class="input-group-prepend">
                    <span class="input-group-text font-small"
                        i18n="laf|Main - control panel@@laf.main.controlPanel.label.station">
                        Station
                    </span>
                </div>
                <input type="text" class="form-control form-control-sm"
                    [value]="getById(references.airports.data, statement.airportId)?.iata" disabled>
            </div>
            <div class="input-group input-group-sm mr-2">
                <div class="input-group-prepend">
                    <span class="input-group-text font-small"
                        i18n="laf|Main - control panel@@laf.main.controlPanel.label.agent">
                        Agent
                    </span>
                </div>
                <input type="text" class="form-control form-control-sm" [value]="statementForm?.get('username')?.value"
                    disabled>
            </div>
            <button type="submit" class="btn btn-xs btn-ico btn-blue" (click)="saveStatement()">
                <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.save">Save</span>
            </button>
        </div>
    </div>
    <div class="position-relative h-100">
        <div class="loading" *ngIf="viewParametrs.loading; else: statementBlock">
            <div class="cssload-spin-box"></div>
        </div>
        <ng-template #statementBlock>
            <ul class="nav nav-tabs mb-3" id="myTab" role="tablist" id="ngbNav">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': activeTab(1)}" id="summary-tab" role="tab" data-tabId="1"
                        (click)="changeTab($event)"
                        i18n="laf|Statement tabs@@laf.statement.tabs.name.summary">Summary</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': activeTab(2)}" id="claim-tab" role="tab" data-tabId="2"
                        (click)="changeTab($event)" i18n="laf|Statement tabs@@laf.statement.tabs.name.claim">Claim</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': activeTab(3)}" id="baggage-tab" role="tab" data-tabId="3"
                        (click)="changeTab($event)"
                        i18n="laf|Statement tabs@@laf.statement.tabs.name.baggage">Baggage</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': activeTab(4)}" id="chat-tab" role="tab" data-tabId="4"
                        (click)="changeTab($event)"
                        i18n="laf|Statement tabs@@laf.statement.tabs.name.chatAndMessages">Chat and messages</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': activeTab(5)}" id="changeLog-tab" role="tab"
                        data-tabId="5" (click)="changeTab($event)"
                        i18n="laf|Statement tabs@@laf.statement.tabs.name.changeLog">Change log</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane tab-statement fade" [ngClass]="{'show active': activeTab(1)}" id="summary"
                    role="tabpanel" aria-labelledby="summary-tab">
                    <div class="row">
                        <div class="col-8 pr-0">
                            <div class="card mb-3">
                                <div class="card-body px-2">
                                    <p class="font-weight-bold text-blue h5 mb-3"
                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.statement">Claim</p>
                                    <span class="font-small mr-2" *ngIf="statementForm.valid">
                                        <span class="font-weight-bold"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.claimNumber">
                                            Claim number</span>&nbsp;{{ statementForm.get('name').value }}
                                    </span>
                                    <span class="font-small mr-2">
                                        <span class="font-weight-bold"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.airport">
                                            Airport</span>&nbsp;{{ getById(references.airports.data,
                                        statementForm.get('airportId').value)?.iata }}
                                    </span>
                                    <span class="font-small mr-2">
                                        <span class="font-weight-bold"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.airline">
                                            Airline</span>&nbsp;{{ getById(references.airlines.data,
                                        statementForm.get('airlineId').value)?.iata }}
                                    </span>
                                    <span class="font-small mr-2">
                                        <span class="font-weight-bold"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.reason">
                                            Reason</span>&nbsp;{{ getById(references.statement_types.data,
                                        statementForm.get('typeId').value)?.nameLocalString }}
                                    </span>
                                    <span class="font-small mr-2">
                                        <span class="font-weight-bold"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.date">
                                            Date</span>&nbsp;{{ statementForm.get('dtInsert').value | date:
                                        'dd.MM.yyyy' : 'UTC+0' }}
                                    </span>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body px-2">
                                    <p class="font-weight-bold text-blue h5 mb-3"
                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger">Passenger
                                    </p>
                                    <div class="row">
                                        <div class="col-4 pr-0">
                                            <table class="w-100 font-small">
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.name">
                                                        Name</td>
                                                    <!-- <td>{{ statement.passenger.passenger.surname }}{{statement.passenger.passenger.name ? "/" }}{{statement.passenger.passenger.name ? statement.passenger.passenger.name }} {{statement.passenger.passenger.middleMame}}</td> -->
                                                    <!-- <td>{{passengerFullName(statement.passenger.passenger)}}</td> -->
                                                    <td>{{ passengerFullName }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.phone">
                                                        Phone</td>
                                                    <td>{{ statementForm.get('passenger').get('phone1').value }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.email">
                                                        Email</td>
                                                    <td>{{ statementForm.get('passenger').get('email').value }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.permanentAddress">
                                                        Permanent address
                                                    </td>
                                                    <td>{{ statementForm.get('passenger').get('address').value }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-4 pr-0">
                                            <table class="w-100 font-small">
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.frequentFlyer">
                                                        Frequent flyer
                                                    </td>
                                                    <td>{{
                                                        statementForm.get('passenger').get('additionalInfo').get('ffp').value
                                                        }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.status">
                                                        Status</td>
                                                    <td>{{
                                                        statementForm.get('passenger').get('additionalInfo').get('status').value
                                                        }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.sex">
                                                        Sex</td>
                                                    <td>{{
                                                        statementForm.get('passenger').get('additionalInfo').get('gender').value
                                                        }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.language">
                                                        Language</td>
                                                    <td>{{
                                                        statementForm.get('passenger').get('additionalInfo').get('language').value
                                                        }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-4 border-left pr-0">
                                            <p class="text-gray-blue-dark mb-0"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.temporaryStay">
                                                Temporary stay
                                            </p>
                                            <table class="w-100 font-small">
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.phone">
                                                        Phone</td>
                                                    <td>{{
                                                        statementForm.get('passenger').get('additionalInfo').get('temporary').get('phone').value
                                                        }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.dateStay">
                                                        Date stay</td>
                                                    <td>{{
                                                        statementForm.get('passenger').get('additionalInfo').get('temporary').get('dueDate').value
                                                        | date: 'dd.MM.yyyy' : 'UTC+0' }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.passenger.temporaryAddress">
                                                        Temporary address</td>
                                                    <td>{{
                                                        statementForm.get('passenger').get('additionalInfo').get('temporary').get('address').value
                                                        }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card">
                                <div class="card-body px-2">
                                    <p class="font-weight-bold text-blue h5"
                                        i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight">
                                        Flight</p>
                                    <table class="w-100 font-small">
                                        <col style="width:35%">
                                        <tr>
                                            <td class="font-weight-bold"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.pnr">
                                                PNR
                                            </td>
                                            <td>{{ statementForm.get('passenger').get('pnr').value }}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.serviceClass">
                                                Service class</td>
                                            <td>{{
                                                statementForm.get('passenger').get('additionalInfo').get('class').value
                                                }}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.baggageCount">
                                                Baggage count</td>
                                            <td>{{ statementForm.get('passenger').get('baggage').get('amount').value
                                                }}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.baggageWeight">
                                                Baggage weight
                                            </td>
                                            <td>{{ statementForm.get('passenger').get('baggage').get('weight').value
                                                }}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.ticketNumber">
                                                Ticket number</td>
                                            <td>{{ statementForm.get('passenger').get('ticket').value }}</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.statement.flight.route">
                                                Route
                                            </td>
                                            <td>
                                                <p class="m-0" *ngFor="let route of arrayStringRoute">
                                                    {{ route }}
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <table class="w-100 font-small table-bordered">
                                <thead class="bg-light-gray font-weight-bold text-center">
                                    <tr>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.tag"
                                            class="align-middle" rowspan="2">Tag</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.status"
                                            class="align-middle" rowspan="2">Status</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.weight"
                                            colspan="3">Weight
                                        </th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.parameters"
                                            class="align-middle" rowspan="2">Parameters</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.forwarding"
                                            colspan="2">
                                            Forwarding
                                        </th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.storage"
                                            class="align-middle" rowspan="2">Storage</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.delivery"
                                            class="align-middle" rowspan="2">Delivery</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.damage"
                                            class="align-middle" rowspan="2">Damage</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.pLost"
                                            class="align-middle" rowspan="2">P/Lost</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.faultStation"
                                            class="align-middle" colspan="3">Fault station</th>
                                    </tr>
                                    <tr>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.checkedIn">
                                            Checked in</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.delivery">
                                            Delivery</th>
                                        <th i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.lost">
                                            Lost</th>

                                        <th
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.flightArrival">
                                            Arrival</th>
                                        <th
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.flightDeparture">
                                            Departure
                                        </th>

                                        <th
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.faultStationAirport">
                                            Airport
                                        </th>
                                        <th
                                            i18n="laf|Statement tabs@@laf.statement.tabs.statement.findTable.faultStationCode">
                                            Code</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="baggage">
                                    <!-- *ngFor="let baggageItem of statement.baggage" -->
                                    <tr *ngFor="let baggageItem of statementFormBaggages.controls; let i=index"
                                        [formGroupName]="i">
                                        <td>{{ baggageItem.get('tag').value }}</td>
                                        <td>{{ getById(baggage_statuses, baggageItem.get('statusId').value)?.nameLocalString }}</td>
                                        <td class="text-center">{{ baggageItem.get('weight').get('expected').value }}</td>
                                        <td class="text-center">{{ baggageItem.get('weight').get('actual').value }}
                                        </td>
                                        <td class="text-center">{{ (baggageItem.get('weight').get('expected').value - baggageItem.get('weight').get('actual').value).toFixed(1) }}</td>
                                        <td class="text-center">{{ getCharacteristicCode(baggageItem) }}</td>
                                        <td> {{ stringRoute(baggageItem.get('forwarding').get('arrival').get('flights')) }}</td>
                                        <td> {{ stringRoute(baggageItem.get('forwarding').get('departure').get('flights')) }}</td>
                                        <!-- <span
                      class="font-weight-bold">{{baggageItem.forwarding.arrival.flights[0]?.flight}}</span><span
                      class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{baggageItem.forwarding.arrival.flights[0]?.dt
                    | date: 'dd.MM.yyyy HH:mm' : 'UTC+0' }} -->
                                        <!-- <td><span
                      class="font-weight-bold">{{baggageItem.forwarding.departure.flights[0]?.flight}}</span><span
                      class="font-weight-bold text-gray-blue-dark">&nbsp;/&nbsp;</span>{{baggageItem.forwarding.departure.flights[0]?.dt
                    | date: 'dd.MM.yyyy HH:mm' : 'UTC+0' }}</td> -->
                                        <td>{{ getById(ADMIN_DATA.storages, baggageItem.get('storage').get('id').value)?.name}}</td>
                                        <td class="text-break">{{ baggageItem.get('delivery').get('address').value }}</td>
                                        <td class="text-center">
                                            <!-- <span *ngIf="damagesCodesString(baggageItem.get('damage').get('damages'))
                                || baggageItem.get('damage').get('note').value
                                || baggageItem.get('damage').get('declaredValue').value"
                      class="material-icons">done</span> -->
                                            <span *ngIf="damagesCodesString(baggageItem.get('damage').get('damages'))">
                                                {{ damagesCodesString(baggageItem.get('damage').get('damages')).join(' / ') }}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span class="material-icons"
                                                *ngIf="getLengthArrayInForm(baggageItem.get('pilferage').get('items')) > 0">done</span>
                                        </td>
                                        <td class="text-center">
                                            {{getById(references.airports.data,
                                            baggageItem.get('airportId').value)?.iata}}
                                        </td>
                                        <td class="text-center">
                                            {{getById(references.irregularity_codes.data,
                                            baggageItem.get('irregularityCode1Id').value)?.code}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="tab-pane tab-statement fade" [ngClass]="{'show active': activeTab(2)}" id="claim"
                    role="tabpanel" aria-labelledby="claim-tab">
                    <div class="row">
                        <div class="col-6 pr-0">
                            <div class="card mb-3">
                                <div class="card-body px-2">
                                    <p class="font-weight-bold text-blue h5 mb-3"
                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.claim">
                                        Claim
                                    </p>
                                    <div class="d-flex justify-content-between mb-3">
                                        <div class="flex-fill">
                                            <div class="input-group flex-nowrap">
                                                <div class="input-group-prepend custom-sm">
                                                    <label for="airportId" class="input-group-text font-small py-0">
                                                        <span
                                                            i18n="laf|Statement tabs@@laf.modal.newStatement.airport">Airport</span>&nbsp;
                                                        <span class="required-asterisk"
                                                            *ngIf="checkRequiredInput(statementForm.get('airportId'))">*</span>
                                                    </label>
                                                </div>
                                                <ng-select class="w-100 custom-sm bg-white select-long"
                                                    [items]="userReferences.airports.data" bindLabel="iata"
                                                    bindValue="id" appendTo="body" [virtualScroll]="true"
                                                    [loading]="userReferences.airports.loading"
                                                    (scroll)="onScrollNgSelect($event, 'airports')"
                                                    (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                                    [searchFn]="customSelectSearch" formControlName="airportId"
                                                    [selectOnTab]="true" id="airportId">
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <div class="font-small">
                                                            <span
                                                                class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="flex-fill ml-3" *ngIf="showedBlock.airline">
                                            <div class="input-group flex-nowrap">
                                                <div class="input-group-prepend custom-sm">
                                                    <label for="airlineId" class="input-group-text font-small py-0">
                                                        <span
                                                            i18n="laf|Statement tabs@@laf.modal.newStatement.airline">Airline</span>&nbsp;
                                                        <span class="required-asterisk"
                                                            *ngIf="checkRequiredInput(statementForm.get('airlineId'))">*</span>
                                                    </label>
                                                </div>
                                                <ng-select class="w-100 custom-sm bg-white select-long"
                                                    [items]="userReferences.airlines.data" bindLabel="iata"
                                                    bindValue="id" appendTo="body" [selectOnTab]="true"
                                                    [virtualScroll]="true" [loading]="userReferences.airlines.loading"
                                                    (scroll)="onScrollNgSelect($event, 'airlines')"
                                                    (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                                                    [searchFn]="customSelectSearch" formControlName="airlineId"
                                                    (change)="checkPassengerStatus()">
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <div class="font-small">
                                                            <span
                                                                class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="input-group flex-nowrap mb-3">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.modal.newStatement.reason">Reason</span>&nbsp;
                                                    <span class="required-asterisk"
                                                        *ngIf="checkRequiredInput(statementForm.get('typeId'))">*</span>
                                                </span>
                                            </div>
                                            <!-- (change)="changeReasonType($event.id)" -->
                                            <ng-select class="w-100 custom-sm bg-white"
                                                [items]="references.statement_types.data" bindValue="id"
                                                bindLabel="nameLocalString" [loading]="references.statement_types.loading"
                                                formControlName="typeId"
                                                [readonly]="statementForm.get('typeId').value">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="d-flex" formGroupName="passenger" *ngIf="showedBlock.baggageCount">
                                        <div class="input-group flex-nowrap mb-3" formGroupName="baggage">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.registrationBaggageCount">
                                                        Registration baggage Count
                                                    </span>
                                                </span>
                                            </div>
                                            <input type="number" class="w-100 form-control custom-sm"
                                                formControlName="amount" />
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0 border-left-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.totalWeight">
                                                        Total weight
                                                    </span>
                                                </span>
                                            </div>
                                            <input type="number" class="w-100 form-control custom-sm"
                                                formControlName="weight" />
                                        </div>
                                    </div>
                                    <div class="flex-fill">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0"
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.claim.commentary">
                                                    Commentary
                                                </span>
                                            </div>
                                            <textarea class="form-control form-control-sm" rows="2"
                                                formControlName="commentary"
                                                appUpperCase>{{ statementForm.get('commentary).value }}</textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body px-2">
                                    <div formGroupName="route" *ngIf="showedBlock.route">
                                        <div formGroupName="full">
                                            <p class="font-weight-bold text-blue h5 mb-3"
                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.route">Route</p>
                                            <app-routes-table [routes]="statementFormRoutesFull.controls.flights"
                                                [referenceAirport]="references.airports"
                                                [referenceAirline]="references.airlines" [arrayName]="'flights'"
                                                [parentForm]="statementFormRoutesFull">
                                            </app-routes-table>
                                        </div>
                                    </div>
                                    <p class="font-weight-bold text-blue h5 mb-3"
                                        i18n="laf|Baggage@@laf.statement.tabs.flightDetail">
                                        Flight detail
                                    </p>
                                    <ng-container formGroupName="passenger">
                                        <div class="row mb-3">
                                            <div class="col-6 pr-0" formGroupName="additionalInfo">
                                                <div class="input-group flex-nowrap">
                                                    <div class="input-group-prepend custom-sm">
                                                        <span class="input-group-text font-small py-0"
                                                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.serviceClass">
                                                            Service class
                                                        </span>
                                                    </div>
                                                    <ng-select [items]="LOCAL_REFERENCES.classes"
                                                        class="w-100 custom-sm select-long" appendTo="body"
                                                        [selectOnTab]="true" formControlName="class"
                                                        [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                                        [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="input-group flex-nowrap">
                                                    <div class="input-group-prepend custom-sm">
                                                        <span class="input-group-text font-small py-0"
                                                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.pnr">
                                                            PNR
                                                        </span>
                                                    </div>
                                                    <input type="text" class="w-100 form-control custom-sm"
                                                        formControlName="pnr" appUpperCase />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group flex-nowrap mb-3" *ngIf="showedBlock.ticketNumber">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0"
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.claim.ticketNumber">
                                                    Ticket number
                                                </span>
                                            </div>
                                            <input type="text" class="w-100 form-control custom-sm"
                                                formControlName="ticket" appUpperCase />
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card" formGroupName="passenger">
                                <div class="card-body px-2">
                                    <p class="font-weight-bold text-blue h5 mb-3"
                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.passenger">
                                        Passenger
                                    </p>
                                    <div class="row mb-3">
                                        <div class="col-lg-6 pr-0">
                                            <div class="input-group flex-nowrap" formGroupName="passenger">
                                                <div class="input-group-prepend custom-sm">
                                                    <span class="input-group-text font-small py-0">
                                                        <span
                                                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.surname">Surname</span>&nbsp;
                                                        <span class="required-asterisk"
                                                            *ngIf="checkRequiredInput(statementForm.get('passenger').get('passenger').get('surname'))">*</span>
                                                    </span>
                                                </div>
                                                <input type="text" class="w-100 form-control custom-sm"
                                                    formControlName="surname" appUpperCase />
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="input-group flex-nowrap" formGroupName="passenger">
                                                <div class="input-group-prepend custom-sm">
                                                    <span class="input-group-text font-small py-0">
                                                        <span
                                                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.name">Name</span>&nbsp;
                                                        <span class="required-asterisk"
                                                            *ngIf="checkRequiredInput(statementForm.get('passenger').get('passenger').get('name'))">*</span>
                                                    </span>
                                                </div>
                                                <input type="text" class="w-100 form-control custom-sm"
                                                    formControlName="name" appUpperCase />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group flex-nowrap mb-3" formGroupName="passenger"
                                        *ngIf="showedBlock.middleName">
                                        <div class="input-group-prepend custom-sm">
                                            <span class="input-group-text font-small py-0">
                                                <span
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.claim.middleName">Middle
                                                    name</span>&nbsp;
                                                <span class="required-asterisk"
                                                    *ngIf="checkRequiredInput(statementForm.get('passenger').get('passenger').get('middleName'))">*</span>
                                            </span>
                                        </div>
                                        <input type="text" class="w-100 form-control custom-sm"
                                            formControlName="middleName" appUpperCase />
                                    </div>
                                    <div class="row mb-3" formGroupName="passenger">
                                        <div class="col-lg-6 pr-0">
                                            <div class="input-group flex-nowrap">
                                                <div class="input-group-prepend custom-sm">
                                                    <span class="input-group-text font-small py-0"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.appeal">
                                                        Appeal
                                                    </span>
                                                </div>
                                                <ng-select [items]="['MR', 'MRS', 'MS', 'MISS']"
                                                    class="w-100 custom-sm select-long" appendTo="body"
                                                    [selectOnTab]="true" formControlName="title">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="input-group flex-nowrap">
                                                <div class="input-group-prepend custom-sm">
                                                    <span class="input-group-text font-small py-0"
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.initials">
                                                        Initials
                                                    </span>
                                                </div>
                                                <input type="text" class="w-100 form-control custom-sm"
                                                    [value]="passengerInitials" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion" id="accordionPassenger">
                                        <div class="card" *ngIf="showedBlock.contactsAndAddress">
                                            <div class="card-header p-0" id="accordionPassengerAdditionalInfo">
                                                <h2 class="mb-0">
                                                    <button
                                                        class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                                        type="button" data-toggle="collapse"
                                                        data-target="#passengerContacts" aria-expanded="true"
                                                        aria-controls="passengerContacts">
                                                        <span class="d-flex align-items-center align-midle">
                                                            <span class="text-light-blue"
                                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.contact">
                                                                Contacts and address
                                                            </span>&nbsp;
                                                            <span
                                                                *ngIf="checkRequiredInput(statementForm.get('passenger').get('phone1'))"
                                                                class="required-asterisk">*</span>
                                                        </span>
                                                        <span class="text-dark-gray ml-2" *ngIf="statementForm.get('passenger').get('phone1').value ||
                                    statementForm.get('passenger').get('email').value ||
                                    statementForm.get('passenger').get('address').value">
                                                            <span class="material-icons">done</span>
                                                        </span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="passengerContacts" class="collapse show"
                                                aria-labelledby="accordionPassengerAdditionalInfo"
                                                data-parent="#accordionPassenger">
                                                <div class="card-body px-2">
                                                    <div class="row mb-3">
                                                        <div class="col-6 pr-0">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0">
                                                                        <span
                                                                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.phone">Phone</span>&nbsp;
                                                                        <span
                                                                            *ngIf="checkRequiredInput(statementForm.get('passenger').get('phone1'))"
                                                                            class="required-asterisk">*</span>
                                                                    </span>
                                                                </div>
                                                                <input type="text" class="w-100 form-control custom-sm"
                                                                    formControlName="phone1" appPhoneMask
                                                                    [phoneId]="'phone1'" id="phone1"
                                                                    [preValue]="statementForm.get('passenger').get('phone1').value"
                                                                    [phoneControl]="statementForm.get('passenger').get('phone1')"
                                                                    maxlength="16" />
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0">
                                                                        <span
                                                                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.email">Email</span>&nbsp;
                                                                        <span
                                                                            *ngIf="checkRequiredInput(statementForm.get('passenger').get('email'))"
                                                                            class="required-asterisk">*</span>
                                                                    </span>
                                                                </div>
                                                                <input type="text" class="w-100 form-control custom-sm"
                                                                    formControlName="email" appUpperCase />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend custom-sm">
                                                            <span class="input-group-text font-small py-0">
                                                                <span
                                                                    i18n="laf|Statement tabs@@laf.statement.tabs.claim.permanentAddress">Permanent
                                                                    address</span>&nbsp;
                                                                <span
                                                                    *ngIf="checkRequiredInput(statementForm.get('passenger').get('address'))"
                                                                    class="required-asterisk">*</span>
                                                            </span>
                                                        </div>
                                                        <textarea class="form-control form-control-sm"
                                                            formControlName="address" appUpperCase
                                                            rows="2">{{ this.statementForm.get('passenger').get('address').value }}</textarea>
                                                    </div>
                                                    <div class="input-group flex-nowrap">
                                                        <div class="input-group-prepend custom-sm">
                                                            <span class="input-group-text font-small py-0"
                                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.notification">
                                                                Notification
                                                            </span>
                                                        </div>
                                                        <ng-select [items]="['Email', 'SMS']" class="w-100 custom-sm"
                                                            formControlName="notification" [multiple]="true">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card" formGroupName="additionalInfo"
                                            *ngIf="showedBlock.temporaryStay">
                                            <div class="card-header p-0" id="accordionTemporaryAddress">
                                                <h2 class="mb-0">
                                                    <button
                                                        class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                                        type="button" data-toggle="collapse"
                                                        data-target="#passengerTemporaryAddress" aria-expanded="false"
                                                        aria-controls="passengerTemporaryAddress">
                                                        <div class="d-flex align-items-center">
                                                            <span class="text-light-blue"
                                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.temporaryStay">
                                                                Temporary stay
                                                            </span>
                                                            <span class="text-dark-gray ml-2"
                                                                *ngIf="statementForm.get('passenger').get('additionalInfo').get('temporary').get('address').value ||
                                    statementForm.get('passenger').get('additionalInfo').get('temporary').get('phone').value ||
                                    statementForm.get('passenger').get('additionalInfo').get('temporary').get('dueDate').value">
                                                                <span class="material-icons">done</span>
                                                            </span>
                                                        </div>
                                                        <span class="text-gray-blue-dark d-flex align-items-center"
                                                            *ngIf="statement.passenger.additionalInfo.temporary.dueDate">
                                                            <span
                                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.dateOfStay">
                                                                Date of stay
                                                            </span>&nbsp;
                                                            {{statement.passenger.additionalInfo.temporary.dueDate |
                                                            date: 'dd.MM.yyyy' : 'UTC+0'}}
                                                        </span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="passengerTemporaryAddress" class="collapse"
                                                aria-labelledby="accordionTemporaryAddress"
                                                data-parent="#accordionPassenger" formGroupName="temporary">
                                                <div class="card-body px-2">
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend custom-sm">
                                                            <span class="input-group-text font-small py-0"
                                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.temporaryAddress">
                                                                Temporary address
                                                            </span>
                                                        </div>
                                                        <textarea class="form-control form-control-sm" rows="2"
                                                            formControlName="address"
                                                            appUpperCase>{{ statementForm.get('passenger').get('additionalInfo').get('temporary').get('address') }}</textarea>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 pr-0">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0"
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.phone">
                                                                        Phone
                                                                    </span>
                                                                </div>
                                                                <input type="text" class="w-100 form-control custom-sm"
                                                                    appPhoneMask [phoneId]="'temporaryPhone'"
                                                                    id="temporaryPhone"
                                                                    [preValue]="statementForm.get('passenger').get('additionalInfo').get('temporary').get('phone').value"
                                                                    [phoneControl]="statementForm.get('passenger').get('additionalInfo').get('temporary').get('phone')"
                                                                    maxlength="16" formControlName="phone" />
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0"
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.dateOfStay">
                                                                        Date of stay
                                                                    </span>
                                                                </div>
                                                                <input type="date" class="form-control custom-sm"
                                                                    (change)="parseDateReactiveForm(statementForm.get('passenger').get('additionalInfo').get('temporary').get('dueDate'), $event.target.value)"
                                                                    [value]="statementForm.get('passenger').get('additionalInfo').get('temporary').get('dueDate').value | date: 'yyyy-MM-dd'">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card" formGroupName="additionalInfo">
                                            <div class="card-header p-0" id="accordionPassengerAdditionalInfo">
                                                <h2 class="mb-0">
                                                    <button
                                                        class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                                        type="button" data-toggle="collapse"
                                                        data-target="#passengerAdditionalInfo" aria-expanded="true"
                                                        aria-controls="passengerAdditionalInfo">
                                                        <div class="d-flex">
                                                            <span
                                                                class="d-flex align-items-center text-light-blue align-midle"
                                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.additionalInfo">
                                                                Additional info
                                                            </span>
                                                            <span class="text-dark-gray ml-2" *ngIf="statementForm.get('passenger').get('additionalInfo').get('ffp').value ||
                                    statementForm.get('passenger').get('additionalInfo').get('gender').value ||
                                    statementForm.get('passenger').get('additionalInfo').get('language').value ||
                                    statementForm.get('passenger').get('additionalInfo').get('status').value">
                                                                <span class="material-icons">done</span>
                                                            </span>
                                                        </div>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="passengerAdditionalInfo" class="collapse"
                                                aria-labelledby="accordionPassengerAdditionalInfo"
                                                data-parent="#accordionPassenger">
                                                <div class="card-body px-2">
                                                    <div class="row mb-3">
                                                        <div
                                                            [ngClass]="{'col-12': !showedBlock.additionalInfo, 'col-6': showedBlock.additionalInfo}">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0"
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.frequentFlyer">
                                                                        Frequent flyer
                                                                    </span>
                                                                </div>
                                                                <input type="text" class="w-100 form-control custom-sm"
                                                                    formControlName="ffp" appUpperCase />
                                                            </div>
                                                        </div>
                                                        <div class="col-6" *ngIf="showedBlock.additionalInfo">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0"
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.category">
                                                                        Category
                                                                    </span>
                                                                </div>
                                                                <!-- [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                              [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'" -->
                                                                <!-- [items]="LOCAL_REFERENCES.passenger_status[getById(this.references.airlines, statementForm.get('airlineId').value)]" -->
                                                                <ng-select [items]="passenger_statuses"
                                                                    class="w-100 custom-sm" formControlName="status"
                                                                    [addTag]="passenger_statuses.length === 0">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3" *ngIf="showedBlock.additionalInfo">
                                                        <div class="col-6">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0"
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.sex">
                                                                        Sex
                                                                    </span>
                                                                </div>
                                                                <ng-select [items]="LOCAL_REFERENCES.gender"
                                                                    class="w-100 custom-sm"
                                                                    [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                                                    [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                                                    formControlName="gender">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0"
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.language">
                                                                        Language
                                                                    </span>
                                                                </div>
                                                                <ng-select [items]="LOCAL_REFERENCES.languages"
                                                                    class="w-100 custom-sm"
                                                                    [bindLabel]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                                                    [bindValue]="globalSettings.language === 'en' ? 'nameEnglish' : 'nameLocal'"
                                                                    formControlName="language" [addTag]="true">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card" formGroupName="additionalInfo"
                                            *ngIf="showedBlock.additionalPassengers">
                                            <div class="card-header p-0" id="accordionPassengerAdditionalPassengers">
                                                <h2 class="mb-0">
                                                    <button
                                                        class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                                        type="button" data-toggle="collapse"
                                                        data-target="#passengerAdditionalPassengers"
                                                        aria-expanded="true"
                                                        aria-controls="passengerAdditionalPassengers">
                                                        <div class="d-flex">
                                                            <span
                                                                class="d-flex align-items-center text-light-blue align-midle"
                                                                i18n="laf|Statement tabs@@laf.statement.tabs.claim.additionalPassengers">
                                                                Additional passengers
                                                            </span>
                                                            <span class="text-dark-gray ml-2"
                                                                *ngIf="statementForm.get('passenger').get('additionalInfo').get('amount').value">
                                                                {{
                                                                statementForm.get('passenger').get('additionalInfo').get('amount').value
                                                                }}
                                                            </span>
                                                        </div>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="passengerAdditionalPassengers" class="collapse"
                                                aria-labelledby="accordionPassengerAdditionalPassengers"
                                                data-parent="#accordionPassenger">
                                                <div class="card-body">
                                                    <div class="row mb-3">
                                                        <div class="col-12">
                                                            <div class="input-group flex-nowrap">
                                                                <div class="input-group-prepend custom-sm">
                                                                    <span class="input-group-text font-small py-0"
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.passengersCount">
                                                                        Passengers count
                                                                    </span>
                                                                </div>
                                                                <input type="number"
                                                                    class="w-100 form-control custom-sm"
                                                                    formControlName="amount" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-container formArrayName="passengers">
                                                        <div class="input-group flex-nowrap mb-3"
                                                            *ngFor="let route of statementPassengers.controls; let i=index"
                                                            [formGroupName]="i">
                                                            <div class="input-group-prepend custom-sm">
                                                                <span class="input-group-text font-small py-0">
                                                                    <span
                                                                        i18n="laf|Statement tabs@@laf.statement.tabs.claim.fullName">Full
                                                                        name</span>&nbsp;{{i + 2}}&nbsp;
                                                                </span>
                                                            </div>
                                                            <input type="text" class="w-100 form-control custom-sm"
                                                                placeholder="Surname" formControlName="surname"
                                                                appUpperCase
                                                                i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.surname" />
                                                            <input type="text" class="w-100 form-control custom-sm"
                                                                placeholder="Name" formControlName="name" appUpperCase
                                                                i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.name" />
                                                            <input type="text" class="w-100 form-control custom-sm"
                                                                placeholder="Middle name" formControlName="middleName"
                                                                appUpperCase
                                                                i18n-placeholder="laf|Statement tabs@@laf.statement.tabs.claim.placeholder.middleName" />
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane tab-statement fade" [ngClass]="{'show active': activeTab(3)}" id="baggage"
                    role="tabpanel" aria-labelledby="baggage-tab">
                    <ul class="nav nav-pills mb-3 baggage-items" role="tablist" formArrayName="baggage">
                        <button class="btn btn-ico btn-light-gray btn-xs mr-2" (click)="addItemBaggage();">
                            <span class="material-icons">add</span>
                        </button>
                        <li class="nav-item" *ngFor="let baggageItem of statementFormBaggages.controls; let i = index"
                            [formGroupName]="i">
                            <a class="nav-link tab-baggage"
                                id="baggage-{{statementFormBaggages.at(i).get('tag').value || 'NO-TAG' + i}}-tab"
                                data-toggle="pill"
                                data-target="#baggage-{{statementFormBaggages.at(i).get('tag').value || 'NO-TAG' + i}}"
                                role="tab" aria-selected="true" (click)="viewParametrs.baggageBlock = i"
                                [ngClass]="viewParametrs.baggageBlock === i ? 'show active btn-xs bg-gray-blue-dark text-white mr-2' : 'btn-xs border bg-light-gray text-dark mr-2'">
                                {{statementFormBaggages.at(i).get('tag').value || 'NO-TAG' | uppercase}}
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content" formArrayName="baggage" #listBaggage>
                        <div class="tab-pane fade show active"
                            *ngFor="let baggageItem of statementFormBaggages.controls; let i = index"
                            id="baggage-{{statementFormBaggages.at(i).get('tag').value || 'NO-TAG' + i}}"
                            role="tabpanel" attr.aria-labelledby="baggage-{{statementFormBaggages.at(i).get('tag').value || 'NO-TAG' + i}}-tab"
                            [ngClass]="{'show active': viewParametrs.baggageBlock === i}">
                            <app-baggage-item
                                [baggageForm]="baggageItem"
                                [statementForm]="statementForm"
                                [baggageItem]="statement.baggage[i]"
                                [references]="references"
                                [statement]="statement"
                                (openChatEmit)="openChat($event)"
                                (removeBaggageEmit)="removeItemBaggage(i)"
                                (setBaggageStatus)="refreshStatement()"
                                (setCancelledStatus)="setCancelledStatus()">
                            </app-baggage-item>
                        </div>
                    </div>
                </div>
                <div class="tab-pane tab-statement fade" [ngClass]="{'show active': activeTab(4)}" id="chat"
                    role="tabpanel" aria-labelledby="chat-tab">
                    <app-chats-tab [statement]="statement" [statementForm]="statementForm"></app-chats-tab>
                </div>
                <div class="tab-pane tab-statement fade" [ngClass]="{'show active': activeTab(5)}" id="changeLog"
                    role="tabpanel" aria-labelledby="changeLog-tab">
                    <logs-tab [statementId]="statement.id"></logs-tab>
                </div>
            </div>


        </ng-template>
    </div>
</form>

<ngb-alert #selfClosingAlert *ngIf="messageAlert.text" [type]="messageAlert.type" [dismissible]="false"
    class="alert-fixed-bottom" (closed)="messageAlert.text = ''; messageAlert.type = ''">
    {{ messageAlert.text }}
</ngb-alert>

<app-modal-message></app-modal-message>