import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { GlobalSettings, ModuleName } from '@settings/global-settings';
import { StatementData, Baggage, Flight, Referance } from '../../types/statements';
import { LostFoundModuleRestApiService } from '../../services/lost-found-rest-api.service';
import { References, REFERENCES_LIST, STATEMENTS, STATUSES } from '../../types/types';
import { ReferencesService } from '../../services/references.service';
import { ADMIN_DATA, LOCAL_REFERENCES } from '../../types/const';
import { parseDate, parseDateReactiveForm } from '@shared/functions/dateFunctions';
import { ChatData } from '../../types/chats';
import { ChatsTabComponent } from './chats-tab/chats-tab.component';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsService } from '../../services/forms.service';
import { Subject } from 'rxjs';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { GlobalI18n } from '@settings/global-i18n';
import { NgSelectFunctions } from '@shared/functions/ngSelectFunctions';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.less']
})
export class StatementComponent implements OnInit {

  private _success = new Subject<{ text: string, type: string }>();
  messageAlert = {
    text: '',
    type: '',
  };

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;
  @ViewChild(ChatsTabComponent) chatsTab: ChatsTabComponent;
  @ViewChild(ModalMessageComponent) modalError: ModalMessageComponent;

  selectFuc = new NgSelectFunctions();

  STATEMENTS = STATEMENTS;
  ADMIN_DATA = ADMIN_DATA;
  LOCAL_REFERENCES = LOCAL_REFERENCES;
  STATUSES = STATUSES;
  parseDate = parseDate;
  parseDateReactiveForm = parseDateReactiveForm;

  classes = {
    baggage: Baggage,
    flight: Flight,
  };

  statement: StatementData = new StatementData();
  statementForm: UntypedFormGroup;

  viewParametrs = {
    loading: true,
    baggageBlock: 0,
  };

  references: References = new References();

  userReferences = {
    airports: { loading: true, data: [] },
    airlines: { loading: true, data: [] },
  }

  numberOfItemsFromEndBeforeFetchingMore = 10;

  // массив добавленных в сравнение
  baggageCompareArray = [];

  selectLoadAnimation = {
    storages: false,
    notifications: false,
    worldTracer: false,
    baggage_statuses_animation: false,
    baggage_brands: false
  };

  buffer = {
    airlines: [],
    airports: [],
  };

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    airlines: 50,
    airports: 50,
  };

  statementOfList: number;

  // активная вкладка
  activeNav = 1;

  // настройки показа полей
  showedBlock;

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
    public referencesService: ReferencesService,
    private fileSaverService: FileSaverService,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public globalI18n: GlobalI18n,
    private formsService: FormsService,
    private element: ElementRef,
  ) {
    this.statementForm = this.fb.group({});
  }

  async ngOnInit(): Promise<void> {
    // Для алерта с сообщениями
    this._success.subscribe((message) => {
      this.messageAlert.text = message.text;
      this.messageAlert.type = message.type;
    });
    this._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    await this.globalSettings.loadComplete();

    const referencesList = [
      REFERENCES_LIST.airlines,
      REFERENCES_LIST.airports,
      REFERENCES_LIST.baggage_types,
      REFERENCES_LIST.baggage_colors,
      REFERENCES_LIST.baggage_materials,
      REFERENCES_LIST.baggage_elements,
      REFERENCES_LIST.baggage_statuses,
      REFERENCES_LIST.baggage_brands,
      REFERENCES_LIST.irregularity_codes,
      REFERENCES_LIST.baggage_damage_types,
      REFERENCES_LIST.baggage_damage_levels,
      REFERENCES_LIST.baggage_internals,
      REFERENCES_LIST.baggage_internal_categories,
    ];
    const statementTypesOrder = [1, 5, 7, 8, 3, 2];
    Promise.all([
      this.referencesService.loadReferences(referencesList).then(data => {
        this.references = data;
      }),
      this.referencesService.loadStatementTypes(null, statementTypesOrder, this.globalSettings.language).then(data => {
        this.references.statement_types = data;
      }),
      this.referencesService.loadMasterData().then(data => {
        if (data.storages && data.storages.length > 0) {
          this.ADMIN_DATA.storages = data.storages;
        }
      }),
      this.referencesService.getUserAirlines().then((data: Array<Referance>) => {
        this.userReferences.airlines.data = data;
        this.userReferences.airlines.loading = false;
      }),
      this.referencesService.getUserAirports().then((data: Array<Referance>) => {
        this.userReferences.airports.data = data;
        this.userReferences.airports.loading = false;
      }),
    ]).then(() => {
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.loadStatement(params.id).then(async (statement: StatementData) => {
            this.statementForm = await this.formsService.createStatementForm(statement);
            this.showedBlock = this.formsService.showedBlock(statement.typeId);
            this.viewParametrs.loading = false;
          });
        }
      });
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.externalBaggageId && params.statementBaggageId) {
          const queryParams: ChatData = {
            statementId: params.statementId,
            statementBaggageId: params.statementBaggageId,
            externalBaggageId: params.externalBaggageId,
            externalName: params.externalName
          };
          this.openChat(queryParams);
        }
      });
    });
  }

  @HostListener('window:keydown', [])
  onWindowKeyDown(evt) {
    // Получаем объект event
    evt = evt || window.event;

    // Определяем нажатие Ctrl+S
    if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 83) {
      // Блокируем появление диалога о сохранении
      if (evt.preventDefault) evt.preventDefault();
      evt.returnValue = false;
      // Запускаем функцию сохранения
      this.saveStatement();
      return false;
    }

    // переключение вкладок по shift+tab
    if (evt.keyCode === 9) {
      if (evt.shiftKey) {
        if (evt.preventDefault) evt.preventDefault();
        const count = document.getElementById('ngbNav').children.length;
        if (count !== this.activeNav) {
          this.activeNav++;
        } else {
          this.activeNav = 1;
        }
      } else {
        if (!document.activeElement) {
          this.activeNav++;
        }
      }
    }
  }

  changeTab(event) {
    this.activeNav = event.target.getAttribute('data-tabId');
  }

  activeTab(event) {
    return event === +this.activeNav;
  }

  get passenger_statuses() {
    let airline = this.getById(this.references.airlines.data, this.statementForm.get('airlineId').value);
    if (airline) {
      return this.LOCAL_REFERENCES.passenger_status[airline.iata] || [];
    } else {
      return [];
    }
  }

  get statementFormRoutes() {
    return this.statementForm.get('route').get('full').get('flights') as UntypedFormArray;
  }

  get statementFormRoutesFull() {
    return this.statementForm.get('route').get('full') as UntypedFormGroup;
  }

  get statementPassengers() {
    return this.statementForm.get('passenger').get('additionalInfo').get('passengers') as UntypedFormArray;
  }

  get statementFormBaggages() {
    return this.statementForm.get('baggage') as UntypedFormArray;
  }

  get passengerInitials() {
    let res = '';
    const name = this.statementForm.get('passenger').get('passenger').get('name').value;
    const middleName = this.statementForm.get('passenger').get('passenger').get('middleName').value;
    if (name.length > 0) {
      res += name[0] + '. ';
    }
    if (middleName.length > 0) {
      res += middleName[0] + '.';
    }
    return res;
  }

  get passengerFullName() {
    let fullName = this.statementForm.get('passenger').get('passenger').get('surname').value;
    const name = this.statementForm.get('passenger').get('passenger').get('name').value;
    const middleName = this.statementForm.get('passenger').get('passenger').get('middleName').value;
    if (name) {
      fullName += '/' + name;
    }
    if (middleName) {
      fullName += ' ' + middleName;
    }
    return fullName;
  }

  get arrayStringRoute() {
    return this.statementFormRoutes.controls.map(route => {
      return (route.get('airline').value || '')
        + (route.get('flight').value || '') + ' '
        + (route.get('departure').value || '') + '/' + (route.get('arrival').value || '') + ' '
        + (route.get('dt').value ? new Date(route.get('dt').value).toLocaleDateString('ru-RU') : '');
    });
  }

  async loadStatement(id: string, index?) {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getStatement(id, xRequestId);
    this.statement = new StatementData(data);
    // this.checkBaggageStatus();
    this.viewParametrs.baggageBlock = 0;
    this.statementOfList = index;
    return this.statement;
  }

  get baggage_statuses() {
    for (const item of this.references.statement_types.data) {
      if (item.id === this.statement.typeId) {
        return item.baggage_statuses;
      }
    }
    return [];
  }

  openChat(ids: ChatData) {
    this.activeNav = 4;
    setTimeout(() => {
      this.chatsTab.openChat(ids);
    }, 250);
  }

  onScrollToEndNgSelect(name) {
    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].data.length <= this.buffer[name].length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  getById(array, id) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

  // autoSave() {
  //   // Объект необходимо преобразовывать в JSON, так как далее происходит
  //   // проверка на наличии изменений по принципу оператора ===
  //   // в таком случае сравнивается сам объект а не его содержимое
  //   this.autoSaveData.next(JSON.stringify(this.statement));
  // }

  checkRouteCoincides(val, index) {
    if (val) {
      this.statement.baggage[index].route = [];
      this.statement.route.full.flights.forEach(el => {
        this.statement.baggage[index].route.push(Object.assign({}, el));
      });
    }
  }

  checkPassengerStatus() {
    const status = this.statementForm.get('passenger').get('additionalInfo').get('status');
    if (!this.passenger_statuses.includes(status.value)) {
      status.patchValue('');
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].data.slice(len, this.bufferSize[name] + len);
    this.selectLoadAnimation[name] = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.selectLoadAnimation[name] = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param term Строка для поиска введеня пользователем
   * @param item Элемент для поиска
   */
  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
      (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
      (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 5 ?
        (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
        :
        (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }

  setAttribute(array, item, val) {
    array[item] = val;
  }

  // TODO: такая же функция используется в компоненте багажа, надо вынести в отдельный файл
  // но сначала нужно переделать хранение справочников
  getCharacteristicCode(baggage: UntypedFormGroup) {
    const characteristics = baggage.get('characteristics') as UntypedFormGroup;
    const codeId = characteristics.get('colorId').value;
    const colorCode = this.getById(this.references.baggage_colors.data, codeId)?.code;
    const typeId = characteristics.get('typeId').value;
    const typeCode = this.getById(this.references.baggage_types.data, typeId)?.code;
    let materialCode = '';
    if (typeCode !== '22D' && typeCode !== '22R') {
      const materialId = characteristics.get('materialId').value;
      materialCode = this.getById(this.references.baggage_materials.data, materialId)?.code;
    }
    const externalElements = characteristics.get('externalElements') as UntypedFormArray;
    const elem = externalElements.controls.reduce((acc, el) => {
      return acc += this.getById(this.references.baggage_elements.data, el.value)?.code;
    }, '');
    return (colorCode ?? 'XX') + (typeCode ?? 'XX') + (materialCode ?? 'X') + elem;
  }

  getLengthArrayInForm(array: UntypedFormArray) {
    return array.length;
  }

  damagesCodesString(damages: UntypedFormArray) {
    let res = [];
    damages.controls.forEach(element => {
      if (element.get('side').value || element.get('type').value || element.get('level').value) {
        res.push((element.get('side')?.value?.replace(' ', '') + element.get('type').value + element.get('level').value).toUpperCase());
      }
    });
    return res;
  }

  stringRoute(routes: UntypedFormArray) {
    const last = routes.at(routes.length - 1);
    if (last) {
      return last.get('airline').value
        + last.get('flight').value + ' '
        + new Date(last.get('dt').value).toLocaleDateString('ru-RU');
    }
  }

  addItemBaggage() {
    const baggage = new Baggage();
    this.viewParametrs.baggageBlock = this.statement.baggage.push(baggage) - 1;
    this.statementFormBaggages.push(this.formsService.createBaggageToStatementForm(baggage, this.statementForm.get('typeId').value));
  }

  async removeItemBaggage(index: number) {
    const mess = this.globalI18n.getMessage(ModuleName.LostFound, 'deleteItemBaggage');
    const errType = 'warning';
    await this.modalError.waitAnswer(mess, errType).then(async res => {
      if (res) {
        this.viewParametrs.baggageBlock = this.viewParametrs.baggageBlock - 1;
        (this.statementForm.get('baggage') as UntypedFormArray).removeAt(index);
      }
    });
  }

  // async changeReasonType(id: number) {
  //   const mess = this.globalI18n.getMessage(ModuleName.LostFound, 'changeStatementType');
  //   const errType = 'warning';
  //   await this.modalError.waitAnswer(mess, errType).then(async res => {
  //     if (res) {
  //       this.refreshStatement(id);
  //     } else {
  //       // Замена значения в реактивной форме на предыдущее
  //       this.statementForm.patchValue({ 'typeId': this.statement.typeId });
  //     }
  //   });
  // }

  setCancelledStatus() {
    for (let i = 0; i < this.statementFormBaggages.controls.length; i++) {
      this.statementFormBaggages.controls[i].get('statusId').setValue(STATUSES.Cancelled);
    }
    this.refreshStatement(this.statementForm.get('typeId').value, true);
  }

  refreshStatement(id: number = this.statementForm.get('typeId').value, cancelled: boolean = false) {
    const tabs = document.getElementsByClassName('nav-link');
    for (let index = 0; index < tabs.length; index++) {
      tabs[index].classList.remove('text-danger');
    }
    this.statement = new StatementData(this.statementForm.value);
    this.formsService.createStatementForm(this.statement, cancelled).then(data => {
      this.statementForm = data;
      this.showedBlock = this.formsService.showedBlock(id);
      this.statementForm.updateValueAndValidity();
    });
  }

  async saveStatement() {
    if (this.statementForm.valid) {
      const xRequestId = this.globalSettings.randomUuid;
      this.statement = new StatementData(this.statementForm.value);
      // Object.assign(this.statement, this.statementForm.value);
      const tabs = document.getElementsByClassName('nav-link');
      for (let index = 0; index < tabs.length; index++) {
        tabs[index].classList.remove('text-danger');
      }
      await this.restApi.updateStatement(this.statement, xRequestId).then(() => {
        const message = this.globalI18n.getMessage(ModuleName.LostFound, 'saved');
        this._success.next({ text: message, type: 'success' });
      }).catch(err => {
        const errorMessage = `${this.globalI18n.getMessage(ModuleName.LostFound, 'claimNotSaved')} ${err.detail}`;
        const errorType = 'error';
        this.modalError.showErrorMess(errorMessage, errorType);
        this.loadStatement(this.statement.id);
        return false;
      });
    } else {
      this.statementForm.markAllAsTouched();
      const message = this.globalI18n.getMessage(ModuleName.LostFound, 'fillInAllRequiredFields');
      this._success.next({ text: message, type: 'danger' });

      // подсветка вкладок с ошибками
      this.searchInvalid(this.statementForm);
    }
  }

  searchInvalid(form: FormGroup) {
    for (const key of Object.keys(form.controls)) {
      if (form.controls[key].invalid) {
        if (Array.isArray((form.controls[key] as FormArray).controls)) {
          (form.controls[key] as FormArray<FormGroup>).controls.forEach(el => {
            this.searchInvalid(el);
          });
        } else {
          const invalidControl = this.element.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]');
          if (invalidControl && invalidControl.length > 0) {
            invalidControl.forEach(invalid => {
              if (invalid.classList.contains('ng-invalid')) {
                const tab = invalid.closest(".tab-statement").getAttribute('aria-labelledby');
                document.getElementById(tab).classList.add('text-danger');

                const tabBaggage = invalid.closest(".tab-pane").getAttribute('aria-labelledby');
                if(tabBaggage) {
                  document.getElementById(tabBaggage).classList.add('text-danger');
                }
              }

            });
          } else if (key !== 'dt') {
            const childForm: FormGroup = <FormGroup>form.controls[key];
            this.searchInvalid(childForm);
          }
        }
      }
    }
  }

  async sendToArchive() {
    this.statementForm.get('archived').patchValue(true);
    // this.statement.archived = true;
    await this.saveStatement();
  }

  async restoreFromArchive() {
    // this.statement.archived = false;
    this.statementForm.get('archived').patchValue(false);
    await this.saveStatement();
  }

  downloadDocument(id: string, type: string) {
    const xRequestId = this.globalSettings.randomUuid;
    return this.restApi.detDocument(id, type, xRequestId).subscribe(data => {
      const blob = new Blob([data], { type: 'application' });
      this.fileSaverService.save(blob, type + '_' + this.statement.name + '.xlsx');
    });
  }

  checkRequiredInput(input) {
    return input.hasValidator(Validators.required);
  }

}
